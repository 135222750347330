import {
  makeStyles,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-final-form';
import _unionBy from 'lodash/unionBy';
import venturesAPI from '../../api/ventures';
import { SelectVenture } from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import { COLORS } from '../../theme/variables';
import VentureAutocomplete from '../autocomplete/venture-autocomplete';
import { TextField, Text } from '../common';
import Button from './button';

const useStyles = makeStyles({
  buttonPopover: {
    minWidth: 150,
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
    padding: '6px 12px 6px 18px',
    cursor: 'auto',

    '&:hover': {
      background: 'white',

      '&:not($buttonPopoverActive)': {
        borderColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },
  },
  buttonPopoverActive: {
    borderColor: COLORS.COLOR_BLUE_BASE,
  },
  error: {
    color: COLORS.COLOR_RED_BASE,
    borderColor: COLORS.COLOR_RED_BASE,
  },
  searchIconBase: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
  },
  searchIconFocused: {
    color: COLORS.COLOR_BLUE_BASE,
  },
  popover: {
    minWidth: 300,
    height: 300,
    maxWidth: 600,
    padding: '10px 0',
    overflow: 'hidden',
  },
  btnLabel: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  searchBox: {
    padding: '0 10px 10px',
  },
  selectList: {
    maxHeight: 'calc(300px - 38px)',
    overflow: 'auto',
  },
  selectContent: {
    width: '100%',
    zIndex: 100,
    top: 55,
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden',
  },
  smallContent: {
    top: 38,
  },
  searchIconOpen: {
    transform: 'rotate(180deg)',
  },
  menuItem: {
    '&:hover': {
      background: COLORS.COLOR_BLUE_LIGHTENED_45,
    },
  },
  activeItem: {
    background: COLORS.COLOR_BLUE_LIGHTENED_45,
  },
  menuItemText: {
    maxWidth: '100%',
    overflowX: 'hidden',
    wordBreak: 'break-word',
  },
});

export interface OptionProps {
  label: string;
  value: string;
}

export interface Props {
  className?: string;
  value?: SelectVenture;
  onChange: (e: any) => any;
  isActive?: boolean;
  small?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

function SelectVentureSessionPopover({
  className,
  value: initialValue,
  onChange: handleChange,
  isActive,
  small,
  required,
  readOnly = false,
}: Props) {
  const classes = useStyles();
  const form = useForm();
  const [selectVenture, setSelectVenture] = useState<SelectVenture | undefined>(
    initialValue,
  );
  const [loadedVentures, setLoadedVentures] = useState<Venture[]>([]);
  const [displayedVentures, setDisplayedVentures] = useState<Venture[]>();
  const [anchorEl, setAnchorEl] = useState(null);
  const searchValue = useRef<string>('');
  const fieldState = form.getFieldState('ventureId');

  const showError =
    ((fieldState?.submitError && !fieldState?.dirtySinceLastSubmit) ||
      fieldState?.error) &&
    fieldState?.touched;

  const loadInitialVentures = useCallback(async () => {
    try {
      let loadedVentures = await venturesAPI.getVenturesByAssignment(
        'noevents',
      );
      if (loadedVentures.length <= 3) {
        const additionalVentures = await venturesAPI.getVenturesByStatus(
          'Active',
        );
        loadedVentures = _unionBy(loadedVentures, additionalVentures, 'id');
      }
      setLoadedVentures(loadedVentures);
      setDisplayedVentures(
        loadedVentures.filter(
          (loadedVenture) => loadedVenture.id !== initialValue?.id,
        ),
      );
    } catch (e) {
      console.log(e);
    }
  }, [initialValue?.id]);

  const handleVenturesSearch = useCallback(
    (searchedVentures: Venture[]) => {
      if (searchValue.current.length <= 2) {
        return;
      }
      const unionSearchedVentures = searchedVentures.filter(
        (searchedVenture) => {
          return searchedVenture.id !== selectVenture?.id;
        },
      );

      setDisplayedVentures(unionSearchedVentures);
    },
    [selectVenture],
  );

  const open = Boolean(anchorEl);
  const isFocused = open || isActive;
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedVenture = selectVenture) => {
    searchValue.current = '';
    setDisplayedVentures(
      loadedVentures.filter(
        (loadedVenture) => loadedVenture.id !== selectedVenture?.id,
      ),
    );
    setAnchorEl(null);
    fieldState?.blur();
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    searchValue.current = e.target.value;

    if (searchValue.current.length <= 2) {
      setDisplayedVentures(
        loadedVentures.filter(
          (loadedVenture) => loadedVenture.id !== selectVenture?.id,
        ),
      );
    }
  };

  useEffect(() => {
    if (!initialValue) {
      setSelectVenture(undefined);
    }
  }, [initialValue]);

  useEffect(() => {
    !readOnly && loadInitialVentures();
  }, [loadInitialVentures, readOnly]);

  return (
    <>
      <Button
        data-testid='session-button-venture-select'
        aria-describedby={id}
        variant='outlined'
        onClick={!readOnly ? handleClick : () => {}}
        aria-invalid={!!showError}
        className={cn(className, classes.buttonPopover, {
          [classes.buttonPopoverActive]: isFocused,
          [classes.error]: showError,
        })}>
        <div className={classes.btnLabel}>
          <Text
            variant='normal'
            className={cn({
              [classes.error]: showError,
            })}>
            {initialValue?.ventureName ||
              (!required ? 'Any venture' : 'Select venture*')}
          </Text>
        </div>
        <InputAdornment position='end'>
          <ArrowDropDownIcon
            className={cn(classes.searchIconBase, {
              [classes.searchIconOpen]: open,
              [classes.searchIconFocused]: isFocused,
            })}
          />
        </InputAdornment>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className={classes.popover}>
          <div className={classes.searchBox}>
            <VentureAutocomplete
              onSearch={handleVenturesSearch}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  data-testid='session-input-venture-select'
                  placeholder='Enter venture name'
                  small={small}
                  onChange={(e) => {
                    handleSearch(e);
                    return onChange(e);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </div>
          <div
            className={cn(classes.selectContent, {
              [classes.smallContent]: small,
            })}>
            <ul className={classes.selectList}>
              {!required && (
                <MenuItem
                  key={`select-venture-any`}
                  data-testid='select-venture-any'
                  value='Any'
                  className={cn(classes.menuItem, {
                    [classes.activeItem]: !selectVenture,
                  })}
                  onClick={() => {
                    setSelectVenture(undefined);
                    handleChange(null);
                    handleClose();
                    setDisplayedVentures([]);
                  }}>
                  <Text variant='normal2'>Any venture</Text>
                </MenuItem>
              )}

              {selectVenture && (
                <MenuItem
                  key={`select-venture-current`}
                  value={selectVenture.id}
                  className={cn(classes.menuItem, classes.activeItem)}
                  onClick={() => {
                    handleClose();
                  }}>
                  <Text variant='normal2' className={classes.menuItemText}>
                    {selectVenture.ventureName}
                  </Text>
                </MenuItem>
              )}

              {displayedVentures &&
                displayedVentures.length > 0 &&
                displayedVentures.map((venture: Venture) => {
                  return (
                    <MenuItem
                      data-testid='session-item-venture-select'
                      key={`select-venture-${venture.id}`}
                      className={classes.menuItem}
                      value={venture.ventureName}
                      onClick={() => {
                        setSelectVenture({
                          ventureName: venture.ventureName,
                          id: venture.id,
                        });
                        handleChange({
                          ventureName: venture.ventureName,
                          id: venture.id,
                        });
                        handleClose({
                          ventureName: venture.ventureName,
                          id: venture.id,
                        });
                      }}>
                      <Text
                        testid={`session-item-venture-select-${venture.ventureName}`}
                        className={classes.menuItemText}
                        variant='normal2'>
                        {venture.ventureName}
                      </Text>
                    </MenuItem>
                  );
                })}
            </ul>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default SelectVentureSessionPopover;
