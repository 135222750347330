import MaterialAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PropsWithChildren, ReactNode } from 'react';

interface TenantAccordionProps {
  summary?: ReactNode;
  expanded?: boolean;
  onExpand?: () => void;
}

const useStyles = makeStyles(() => ({
  accordion: {
    padding: 0,

    '& .MuiAccordionSummary-root': {
      cursor: 'default !important',
    },

    '& .MuiAccordionSummary-content': {
      minHeight: 40,
    },
  },
}));

export default function Accordion({
  summary,
  children,
  expanded,
  onExpand,
}: PropsWithChildren<TenantAccordionProps>) {
  const classes = useStyles();

  return (
    <MaterialAccordion expanded={expanded} className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon onClick={onExpand} />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MaterialAccordion>
  );
}
