export const MENTOR_RULES = {
  edit: false,
  help: true,

  dashboard: {
    view: false,
    showStatistics: false,
    clickLinksVentures: false,
    clickLinksSessions: false,
    clickCreateButtonVentue: false,
    clickCreateButtonMentor: false,
    clickCreateButtonSession: false,
    showListUpcomingEvents: false,
    switchListUpcomingEvents: false,
  },
  mentor: {
    view: true,
    create: false,
    export: false,
    import: false,
    filters: true,
    statistics: true,
    details: {
      view: true,
      update: false,
      field: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        linkedIn: true,
        address: false,
        dateOfBirth: false,
        dateOfEnrollment: false,
        specializations: false,
      },
    },
    assignemnts: {
      view: false,
      viewTable: false,
      update: false,
    },
    notes: {
      view: false,
      update: false,
    },
    additionalInfo: {
      view: false,
      update: false,
    },
    logs: {
      view: false,
      update: false,
    },
  },
  venture: {
    view: true,
    create: false,
    export: false,
    import: false,
    filters: true,
    updateProfile: false,
    wizard: true,
    details: {
      view: true,
      update: false,
      additional: {
        update: false,
      },
    },
    founders: {
      view: true,
      update: false,
    },
    assignemnts: {
      view: true,
      update: false,
    },
    notes: {
      view: true,
      update: true,
    },
    finance: {
      view: true,
      update: false,
    },
    additionalInfo: {
      view: true,
      update: false,
    },
    logs: {
      view: true,
      update: false,
    },
  },
  founder: {
    view: true,
    create: false,
    export: false,
    import: false,
    filters: false,
    updateProfile: false,
    details: {
      view: true,
      update: false,
      field: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        linkedInProfile: true,
        dateOfBirth: false,
        dateOfEnrollment: false,
        specializations: false,
        address: false,
      },
    },
    ventures: {
      view: true,
      update: false,
    },
    additionalInfo: {
      view: false,
      update: false,
    },
    notes: {
      view: false,
      update: false,
    },
    logs: {
      view: true,
      update: false,
    },
  },
  session: {
    view: true,
    create: false,
    filters: false,
    calendar: false,
    wizard: true,
    details: {
      view: true,
      update: false,
      founderUpdate: true,
      mentorUpdate: true,
      addToCalendar: true,
      acceptance: true,
      join: true,
    },

    notes: {
      view: true,
      update: true,
    },
  },
  gathering: {
    view: false,
    create: true,
    filters: true,
    calendar: true,
    details: {
      view: true,
      update: false,
      founderUpdate: true,
      mentorUpdate: true,
    },
    founders: {
      view: false,
      update: false,
    },
    mentors: {
      view: false,
      update: false,
    },
    guests: {
      view: false,
      update: false,
    },
    attendees: {
      view: false,
      update: false,
    },
    notes: {
      view: false,
      update: false,
    },
  },
  personalNotes: {
    view: true,
    create: true,
    update: true,
  },
  education: {
    view: true,
    edit: false,
  },
  channels: {
    view: false,
  },
  settings: {
    view: false,
  },
  automation: {
    view: false,
  },
  userManagement: {
    view: false,
  },
  customQuestions: {
    view: false,
    edit: false,
  },
  consents: {
    consents: false,
    view: false,
  },
  modules: {
    programMaterials: {
      view: true,
    },
    reports: {
      view: false,
    },
  },
  advisor: {
    logs: {
      view: false,
    },
  },
  specialization: {
    create: false,
    update: false,
  },
  appointment: {
    details: {
      create: false,
      update: false,
      cancel: false,
      inviteAll: false,
      payment: false,
      refund: false,
    },
  },
};

export const ADMIN_RULES = {
  edit: true,
  help: true,

  dashboard: {
    view: true,
    showStatistics: true,
    clickLinksVentures: true,
    clickLinksSessions: true,
    clickCreateButtonVentue: true,
    clickCreateButtonMentor: true,
    clickCreateButtonSession: true,
    showListUpcomingEvents: true,
    switchListUpcomingEvents: true,
  },
  mentor: {
    view: true,
    create: true,
    export: true,
    import: true,
    filters: true,
    statistics: true,
    details: {
      view: true,
      update: true,
      field: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        linkedIn: true,
        address: true,
        dateOfBirth: true,
        dateOfEnrollment: true,
        specializations: true,
      },
    },
    assignemnts: {
      view: true,
      viewTable: true,
      update: true,
    },
    notes: {
      view: true,
      update: true,
    },
    additionalInfo: {
      view: true,
      update: true,
    },
    logs: {
      view: true,
      update: true,
    },
  },
  venture: {
    view: true,
    create: true,
    export: true,
    import: true,
    filters: true,
    updateProfile: true,
    wizard: true,
    details: {
      view: true,
      update: true,
      additional: {
        update: true,
      },
    },
    founders: {
      view: true,
      update: true,
    },
    assignemnts: {
      view: true,
      update: true,
    },
    notes: {
      view: true,
      update: true,
    },
    finance: {
      view: true,
      update: true,
    },
    additionalInfo: {
      view: true,
      update: true,
    },
    logs: {
      view: true,
      update: true,
    },
  },
  founder: {
    view: true,
    create: true,
    export: true,
    import: true,
    filters: true,
    updateProfile: true,
    details: {
      view: true,
      update: true,
      field: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        linkedInProfile: true,
        dateOfBirth: true,
        dateOfEnrollment: true,
        specializations: true,
        address: true,
      },
    },
    ventures: {
      view: true,
      update: true,
    },
    additionalInfo: {
      view: true,
      update: true,
    },
    notes: {
      view: true,
      update: true,
    },
    logs: {
      view: true,
      update: true,
    },
  },
  session: {
    view: true,
    create: true,
    filters: true,
    calendar: true,
    wizard: true,
    details: {
      view: true,
      update: true,
      founderUpdate: true,
      mentorUpdate: true,
      inviteAll: true,
      cancel: true,
      addToCalendar: true,
      acceptance: false,
      join: true,
    },
    notes: {
      view: true,
      update: true,
    },
  },

  gathering: {
    view: false,
    create: true,
    filters: true,
    calendar: true,
    details: {
      view: true,
      update: true,
      founderUpdate: true,
      mentorUpdate: true,
    },
    founders: {
      view: true,
      update: true,
    },
    mentors: {
      view: true,
      update: true,
    },
    guests: {
      view: true,
      update: true,
    },
    attendees: {
      view: true,
      update: true,
    },
    notes: {
      view: true,
      update: true,
    },
  },
  personalNotes: {
    view: true,
    create: true,
    update: true,
  },
  education: {
    view: true,
    edit: true,
  },
  channels: {
    view: true,
  },
  settings: {
    view: true,
  },
  automation: {
    view: true,
  },
  userManagement: {
    view: true,
    mentor: true,
    admin: true,
    founder: true,
  },
  customQuestions: {
    view: true,
    edit: true,
  },
  consents: {
    update: true,
    view: true,
  },
  modules: {
    programMaterials: {
      view: true,
    },
    reports: {
      view: true,
    },
    enrollment: {
      view: true,
      update: true,
    },
  },
  advisor: {
    logs: {
      view: true,
    },
  },
  specialization: {
    create: true,
    update: true,
  },
  appointment: {
    details: {
      create: true,
      update: true,
      cancel: true,
      inviteAll: true,
      payment: true,
      refund: true,
    },
  },
};

export const FOUNDER_RULES = {
  venture: {
    view: true,
    create: false,
    export: false,
    import: false,
    filters: false,
    updateProfile: false,
    wizard: false,
    details: {
      view: true,
      update: false,
      additional: {
        update: true,
      },
    },
    founders: {
      view: false,
      update: false,
    },
    assignemnts: {
      view: true,
      update: false,
    },
    notes: {
      view: false,
      update: true,
    },
    finance: {
      view: false,
      update: false,
    },
    additionalInfo: {
      view: true,
      update: true,
    },
    logs: {
      view: false,
      update: false,
    },
  },
  session: {
    view: true,
    wizard: true,
    details: {
      view: true,
      cancel: true,
      submitFounderNotes: true,
      submitAgenda: true,
      addToCalendar: true,
      acceptance: true,
      join: true,
    },
    notes: {
      view: true,
      update: true,
    },
  },
  mentor: {
    view: true,
    mentorRequest: true,
    statistics: false,
    details: {
      view: true,
      manageMentor: true,
      writeReview: true,
      field: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        linkedIn: true,
        address: false,
        dateOfBirth: false,
        dateOfEnrollment: false,
        specializations: false,
      },
    },
    additionalInfo: {
      view: true,
    },
  },
  goal: {
    view: true,
    create: true,
    overview: {
      view: true,
      update: true,
    },
    checkins: {
      view: true,
      update: true,
      create: true,
    },
    tasks: {
      view: true,
      update: true,
      create: true,
    },
    log: {
      view: true,
    },
  },
  personalNotes: {
    view: true,
    create: true,
    update: true,
  },
  education: {
    view: true,
    edit: true,
  },
  channels: {
    view: false,
  },
  settings: {
    view: false,
  },
  automation: {
    view: false,
  },
  customQuestions: {
    view: false,
    edit: false,
  },
  modules: {
    programMaterials: {
      view: false,
    },
    reports: {
      view: false,
    },
    enrollment: {
      view: false,
      update: false,
    },
  },
  advisor: {
    logs: {
      view: false,
    },
  },
  specialization: {
    create: false,
    update: false,
  },
  appointment: {
    details: {
      create: false,
      update: false,
      cancel: false,
      inviteAll: false,
      payment: false,
      refund: false,
    },
  },
};
