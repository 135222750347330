import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { formatDateToRFC } from '../../utils/date';
import { StringDate } from '../../utils/date/StringDate';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  Button,
  FormGroup,
  FormButtons,
  AttachFilesButton,
  BeforeUnload,
} from '../common';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile,
} from '../common/attachment-card';
import { FormDateTimeInput, TextFieldWysiwyg } from './wrappers';

export interface Values {
  contents: string;
  attachments: Attachment[];
  creationDate: StringDate;
}

interface AttachmentsWithStatus {
  attachment: Attachment;
  loading: boolean;
}

type Errors = {
  [K in keyof Values]?: string;
};

interface NoteFormProps {
  loading?: boolean;
  note?: {
    contents: string;
    attachments: Attachment[];
    creationDate: StringDate;
  };
  onSubmit: (parsedValues: Values) => any;
  onCancel: () => any;
  onUploadFile?: (file: File) => Promise<string>;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  field: {
    '& [name="contents"]': {
      minHeight: 107,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  createdTimeInput: {
    width: '100%',
    maxWidth: 250,
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
}));

const validateForm = (values: Values) => {
  const errors: Errors = {};
  if (!values.contents) {
    errors.contents = 'Required';
  }
  return errors;
};

function NoteForm({
  note,
  loading = false,
  onSubmit,
  onUploadFile,
  onCancel,
}: NoteFormProps) {
  const classes = useStyles();
  const initialValues: Values = {
    contents: note?.contents || '',
    attachments: note?.attachments || [],
    creationDate: note?.creationDate || formatDateToRFC(new Date()),
  };
  const [attachmentsWithStatus, setAttachmentsWithStatus] = useState<
    AttachmentsWithStatus[]
  >(
    initialValues.attachments.map((attachment) => ({
      attachment,
      loading: false,
    })),
  );

  const handleSubmit = (values: Values) => {
    onSubmit({
      contents: values.contents,
      attachments: attachmentsWithStatus.map(
        (attachment) => attachment.attachment,
      ),
      creationDate: values.creationDate,
    });
  };

  const handleFileUpload = async (file: File) => {
    if (onUploadFile) {
      try {
        const fileIndex = attachmentsWithStatus.length;
        setAttachmentsWithStatus((prevAttachments) => [
          ...prevAttachments,
          {
            attachment: getAttachmentFromFile(file),
            loading: true,
          },
        ]);
        const fileURL = await onUploadFile(file);
        setAttachmentsWithStatus((prevAttachments) => {
          return prevAttachments.map((prevAttach, prevAttachIndex) => {
            if (prevAttachIndex !== fileIndex) {
              return prevAttach;
            }
            return {
              ...prevAttach,
              attachment: {
                ...prevAttach.attachment,
                url: fileURL,
              },
              loading: false,
            };
          });
        });
      } catch (e: any) {}
    }
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  const handleFileRemove = (index: number) => {
    setAttachmentsWithStatus((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validateForm}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <BeforeUnload
            when={formProps.dirty && !loading}
            title='Leave the page'
            body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
            disabled={loading}
            confirmButtonRenderer={({ onConfirm }) => (
              <Button
                variant='outlined'
                onClick={async () => {
                  await formProps.handleSubmit();
                  onConfirm();
                }}
                disabled={loading || !formProps.valid}>
                {loading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Save the changes'
                )}
              </Button>
            )}
          />
          <div className={classes.topSection}>
            <Field<string>
              name={'creationDate'}
              testId='creation-date-input'
              component={FormDateTimeInput}
              label='Creation date'
              small={true}
              className={classes.createdTimeInput}
              parse={(value) => {
                try {
                  if (value) {
                    return formatDateToRFC(value);
                  }
                  return '';
                } catch (e: any) {
                  return 'invalid';
                }
              }}
            />
          </div>
          <FormGroup>
            <Field<string>
              className={classes.field}
              name='contents'
              component={TextFieldWysiwyg}
              placeholder='Type here...'
              multiline
              testid='form-note-text'
            />
          </FormGroup>
          {onUploadFile ? (
            <div className={classes.attachBtn}>
              <AttachFilesButton onChange={handleSelectFile} />
            </div>
          ) : null}
          <div className={classes.attachmentsList}>
            {attachmentsWithStatus.map(
              ({ attachment, loading }, attachIndex) => (
                <div key={attachIndex} className={classes.attachment}>
                  <AttachmentCard
                    attachment={attachment}
                    loading={loading}
                    onRemove={() => handleFileRemove(attachIndex)}
                  />
                </div>
              ),
            )}
          </div>
          <FormButtons className={classes.formButtons}>
            <Button
              data-testid='form-note-submit'
              type='submit'
              disabled={loading}
              className={CLASS_TRACKING.INTERNAL_ACTION}>
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Save'
              )}
            </Button>
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default NoteForm;
