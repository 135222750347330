import { IconButton, Link as MaterialLink } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { SyntheticEvent, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Venture } from '../../api/ventures/types/Venture';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { ConfirmButton, Text, Link } from '../common';

interface VenturesTableProps {
  ventures: Venture[];
  disabledVenturesIds: {
    [x: string]: boolean;
  };
  onRemove: (venture: Venture) => any;
  readOnly?: boolean;
}

const useStyles = makeStyles({
  table: {
    maxWidth: 480,
  },
  title: {
    width: '100%',
    padding: '14px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  name: {
    width: 420,
    marginRight: 10,

    '& a': {
      display: 'block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  text: {
    color: COLORS.COLOR_BLUE_DARKENED_10,
  },
});

function VenturesTable({
  ventures,
  disabledVenturesIds,
  onRemove,
  readOnly = false,
}: VenturesTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const handleRemove = (venture: Venture) => () => {
    onRemove(venture);
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  return (
    <div className={classes.table}>
      <div className={classes.title}>
        <Text variant='normal2' bold>
          Ventures
        </Text>
      </div>
      {ventures.map((venture, index) => {
        const isDisabled = disabledVenturesIds[venture.id];

        return (
          <div
            key={venture.id}
            className={classes.row}
            data-testid={`assigned-venture-${index}`}>
            <div className={classes.name}>
              <MaterialLink
                onClick={stopPropagation}
                component={Link}
                to={{
                  pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                    ventureId: venture.id,
                  }),
                  state: {
                    prevPath: currentPath,
                  },
                }}>
                <Text className={classes.text} variant='normal'>
                  {venture.ventureName}
                </Text>
              </MaterialLink>
            </div>
            {!readOnly && (
              <div>
                <ConfirmButton
                  title='Remove the assignment?'
                  body='Sure you want to remove the assignment? Changes can’t be undone'
                  successProps={{
                    btnLabel: 'Remove',
                    onSuccess: handleRemove(venture),
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <IconButton
                      onClick={onClick}
                      disabled={isDisabled}
                      data-testid={`delete-button-${index}`}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default VenturesTable;
