import { makeStyles } from '@material-ui/core';
import { PropsWithChildren, ReactNode } from 'react';
import { Accordion } from '../../components/common';

interface TenantAccordionProps {
  summary?: ReactNode;
  actions?: ReactNode;
  expanded?: boolean;
  onExpand?: () => void;
}

const useStyles = makeStyles((theme) => ({
  summary: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',

    '@media (max-width: 700px)': {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(1),
    },
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  actions: {
    minWidth: 40,
  },
}));

function TenantAccordion({
  summary,
  actions,
  expanded,
  onExpand,
  children,
}: PropsWithChildren<TenantAccordionProps>) {
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded}
      onExpand={onExpand}
      summary={
        <div className={classes.summaryContainer}>
          <div className={classes.summary}>{summary}</div>
          <div className={classes.actions}>{actions}</div>
        </div>
      }>
      {children}
    </Accordion>
  );
}

export default TenantAccordion;
