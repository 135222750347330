import { Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Text } from '../common';

export interface DashboardEventType {
  id: string;
  startTime: string;
  endTime: string;
  title: string;
  ventureName: string;
}

export interface DashboardEventProps {
  event: DashboardEventType;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    textDecoration: 'none',
    width: '100%',
    padding: '28px 32px',
    background: '#fff',
    boxShadow:
      '0px 0px 0px 1px rgba(34, 91, 187, 0.08), 0px 0px 4px rgba(51, 126, 255, 0.08), 0px 4px 8px rgba(51, 126, 255, 0.04)',
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '0 0 8px 8px',
    color: 'inherit',

    '&:first-child': {
      borderRadius: '0 0 8px 8px',
    },

    '&:last-child': {
      borderRadius: 8,
    },

    '&:not(:last-child)': {
      marginTop: '-5px',
    },

    '&:hover': {
      zIndex: 1,
      borderRadius: 8,
      boxShadow:
        '0px 0px 0px 1px rgba(34, 91, 187, 0.08), 0px -4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.08), 0px 24px 48px rgba(51, 126, 255, 0.08)',

      '& $title': {
        color: COLORS.COLOR_BLUE_BASE,
      },

      '& $iconContainer': {
        display: 'flex',
      },
    },
  },
  eventTime: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 32,
    minWidth: 62,
  },
  expiredAt: {
    marginTop: 2,
    color: COLORS.COLOR_TEXT_LIGHTENED_30,
  },
  title: {
    margin: '-5px 0 2px',
    wordBreak: 'break-word',
  },
  body: {
    maxWidth: 600,
  },
  description: {
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    wordBreak: 'break-word',
  },
  iconContainer: {
    flexGrow: 1,
    alignSelf: 'center',
    display: 'none',
    justifyContent: 'flex-end',
  },
  icon: {
    fontSize: 27,
    color: COLORS.COLOR_BLUE_BASE,
  },
});

function DashboardEvent({ event }: DashboardEventProps) {
  const classes = useStyles();

  return (
    <Link
      data-testid='dashboard-event'
      to={getRoutePath(Pages.SESSIONS_DETAILS, { sessionId: event.id })}
      className={cn(classes.container, CLASS_TRACKING.DASHBOARD_LINK)}>
      <span className={classes.eventTime}>
        <Text variant='upper1'>{event.startTime}</Text>
        <Text className={classes.expiredAt} inherit variant='upper1'>
          {event.endTime}
        </Text>
      </span>
      <span className={classes.body}>
        <Typography className={classes.title} variant='h5'>
          {event.title}
        </Typography>
        <Text className={classes.description} variant='normal2'>
          {event.ventureName}
        </Text>
      </span>
      <span className={classes.iconContainer}>
        <ArrowForwardIcon className={classes.icon} />
      </span>
    </Link>
  );
}

export default DashboardEvent;
