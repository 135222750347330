import { makeStyles, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Audience } from '../../api/CustomQuestion/types/Audience';
import authAPI from '../../api/auth';
import { Role } from '../../api/user/Role';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import TenantAccordion from '../../pages/tenant/tenant-accordion';
import TenantCustomQuestionsBlock from '../../pages/tenant/tenant-custom-questions-page/tenant-custom-question-block';
import { Pages } from '../../router/constants';
import { TestId } from '../Testing/TestId';
import { Text, Toggler, SnackMessage } from '../common';
import { WithRole } from '../common/WithRole';
import { WithRule } from '../common/WithRule';
import FormSection from './form-section';

interface Props {
  links: {
    mentors: string;
    ventures: string;
  };
}

const useStyles = makeStyles({
  container: {
    maxWidth: 670,

    '@media (max-width: 700px)': {
      marginLeft: -40,
      marginRight: -40,
    },
  },
  sectionTitle: {
    marginTop: 25,
    marginBottom: 25,

    '&:first-child': {
      marginTop: 0,
    },

    '@media (max-width: 700px)': {
      marginLeft: 16,
    },
  },
});

function boolToToggle(v: boolean): 'on' | 'off' {
  switch (v) {
    case true:
      return 'on';
    case false:
      return 'off';
  }
}

function toggleToBool(v: 'on' | 'off'): boolean {
  switch (v) {
    case 'on':
      return true;
    case 'off':
      return false;
  }
}

function FormsScreen({ links }: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { user, updateUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [copied, setCopied] = useState<string | null>(null);

  const linkList = useMemo(
    () => [
      {
        id: 'mentor',
        label: `${rb('mentor-u')} Application Form`,
        link: links.mentors,
        description: `Click on the gear icon to tailor the ${rb(
          'mentor-u',
        )} Application Form's description, ensuring applicants are aware they're applying to your organization.`,
      },
      {
        id: 'venture',
        label: 'Venture Application Form',
        link: links.ventures,
        description:
          "Click on the gear icon to tailor the Venture Application Form's description, ensuring applicants are aware they're applying to your organization.",
      },
    ],
    [links.mentors, links.ventures, rb],
  );

  const handleCopy = (text: string, id: string) => {
    navigator.clipboard.writeText(text);
    setCopied(id);
  };

  const handleChangeImpactfulMentor = useCallback(
    async (val: 'on' | 'off') => {
      const value = toggleToBool(val);
      try {
        if (user) {
          const updatedTenant = await authAPI.updateTenant(user?.id, {
            ...user,
            mostImpactfulMentorEnabled: value,
            timeZone: user.timeZone ?? 'US/Eastern',
          });
          updateUser(updatedTenant);
          const message = value
            ? `Most Impactful ${rb('mentor-u')} question is enabled`
            : `Most Impactful ${rb('mentor-u')} question is disabled`;
          enqueueSnackbar(message, {
            variant: 'success',
          });
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        const message = value
          ? `An error occurred while activating most impactful ${rb(
              'mentor',
            )} module. Please, try again.`
          : `An error occurred while deactivating most impactful ${rb(
              'mentor',
            )} module. Please, try again.`;

        enqueueSnackbar(message, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, rb, updateUser, user],
  );

  const handleChangeReportBasedScheduling = useCallback(
    async (val: 'on' | 'off') => {
      const value = toggleToBool(val);
      try {
        if (user) {
          const updatedTenant = await authAPI.updateTenant(user?.id, {
            ...user,
            reportBasedScheduleEnabled: value,
            timeZone: user.timeZone ?? 'US/Eastern',
          });
          updateUser(updatedTenant);
          const message = value
            ? `Lead ${rb('mentor-u')} Report Scheduling is enabled`
            : `Lead ${rb('mentor-u')} Report Scheduling is disabled`;
          enqueueSnackbar(message, {
            variant: 'success',
          });
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        const message = value
          ? `An error occurred while activating Lead ${rb(
              'mentor-u',
            )} Report Scheduling module. Please, try again.`
          : `An error occurred while deactivating Lead ${rb(
              'mentor-u',
            )} Report Scheduling module. Please, try again.`;

        enqueueSnackbar(message, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, rb, updateUser, user],
  );

  const onExpand = useCallback(
    (id: string) => () =>
      setExpandedSection((prev) => (prev === id ? null : id)),
    [],
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (copied) {
      timeout = setTimeout(() => {
        setCopied(null);
      }, 4000);
    }

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div className={classes.container}>
      <Typography
        variant='h4'
        className={classes.sectionTitle}
        data-testid='links-page-href'>
        Session Forms
      </Typography>
      <WithRole roles={[Role.Admin, Role.Manager]}>
        <TenantAccordion
          expanded={expandedSection === 'impactful-mentor'}
          onExpand={onExpand('impactful-mentor')}
          summary={
            <>
              <Typography variant='h5'>
                Most Impactful {rb('mentor-u')} Assessment
              </Typography>
              <TestId testId={'impactful-mentor-enable'}>
                <Toggler<'on' | 'off'>
                  value={boolToToggle(
                    user?.mostImpactfulMentorEnabled ?? false,
                  )}
                  options={mostImpactfulMentor}
                  onChange={handleChangeImpactfulMentor}
                  withIcon={false}
                  mini
                />
              </TestId>
            </>
          }>
          <div>
            <Text variant='normal'>
              When this option is enabled, then there will be a question for{' '}
              {rb('mentors')} and founders to select the most impactful{' '}
              {rb('mentor')}. This option affects the following forms: Lead{' '}
              {rb('mentor-u')} Report, {rb('mentor-u')} Assessments and Founder
              Notes forms, as well as Ventures Notes.
            </Text>
            <br />
            <br />
            <Text variant={'normal'}>
              Information collected through this form is used by Traction5 to
              identify {rb('mentors')} who are seen as most impactful by both
              founder and {rb('mentors')} to better understand how we can
              improve {rb('mentorship')}.
            </Text>
          </div>
        </TenantAccordion>
      </WithRole>

      <TenantAccordion
        expanded={expandedSection === 'report-based-scheduling'}
        onExpand={onExpand('report-based-scheduling')}
        summary={
          <>
            <Typography variant='h5'>
              Lead {rb('mentor-u')} Report Scheduling
            </Typography>
            <TestId testId={'report-based-scheduling-enable'}>
              <Toggler<'on' | 'off'>
                value={boolToToggle(user?.reportBasedScheduleEnabled ?? true)}
                options={reportBasedSchedulingOptions}
                onChange={handleChangeReportBasedScheduling}
                withIcon={false}
                mini
              />
            </TestId>
          </>
        }>
        <div>
          <Text variant='normal'>
            Disabling this feature removes the ability to select the next
            meeting date from the Lead {rb('mentor-u')} Report. It's generally
            advised to enable this to facilitate choosing the next session date
            with all participants present. Programs with pre-scheduled sessions
            often disable this to prevent overlapping bookings.
          </Text>
        </div>
      </TenantAccordion>

      <TenantAccordion
        expanded={expandedSection === 'lead-mentor-report-form'}
        onExpand={onExpand('lead-mentor-report-form')}
        summary={
          <Typography variant='h5'>
            Lead {rb('mentor-u')} Report Form
          </Typography>
        }
        actions={
          <Tooltip title={`Lead ${rb('mentor-u')} Report Form`}>
            <IconButton
              component={Link}
              to={Pages.TENANT_FORMS_LEAD_MENTOR_REPORT}
              data-testid='lead-mentor-report-form-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        }>
        <div>
          <Text variant='normal'>
            This section contains a number of switches that will allow you to
            customize how Lead {rb('mentor-u')} Report Form will look like, what
            information should be accumulated and stored.
          </Text>
        </div>
      </TenantAccordion>

      <TenantAccordion
        expanded={expandedSection === 'founder-notes-report-form'}
        onExpand={onExpand('founder-notes-report-form')}
        summary={
          <Typography variant='h5'>Founder Notes Report Form</Typography>
        }
        actions={
          <Tooltip title='Founder Notes Report Form'>
            <IconButton
              component={Link}
              to={Pages.TENANT_FORM_FOUNDER_NOTES_REPORT}
              data-testid='founder-notes-report-form-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        }>
        <div>
          <Text variant='normal'>
            This section contains a number of switches that will allow you to
            customize how Founder Notes Report Form will look like, what
            information should be accumulated and stored.
          </Text>
        </div>
      </TenantAccordion>

      <Typography
        variant='h4'
        className={classes.sectionTitle}
        data-testid='links-page-href'>
        Application Forms
      </Typography>

      {linkList.map((link) => (
        <FormSection
          key={link.id}
          expanded={expandedSection === `fs-${link.id}`}
          onExpand={onExpand(`fs-${link.id}`)}
          onCopy={handleCopy}
          copied={copied}
          {...link}
        />
      ))}

      <WithRule rule='customQuestions.view'>
        <TenantCustomQuestionsBlock
          expanded={expandedSection === `cq-mentor-additional-info`}
          onExpand={onExpand(`cq-mentor-additional-info`)}
          audience={Audience.MENTOR_ADDITIONAL_INFO}
        />
        <TenantCustomQuestionsBlock
          expanded={expandedSection === `cq-founder-additional-info`}
          onExpand={onExpand(`cq-founder-additional-info`)}
          audience={Audience.FOUNDER_ADDITIONAL_INFO}
        />
        <TenantCustomQuestionsBlock
          expanded={expandedSection === `cq-venture-additional-info`}
          onExpand={onExpand(`cq-venture-additional-info`)}
          audience={Audience.VENTURE_ADDITIONAL_INFO}
        />
      </WithRule>
    </div>
  );
}

const mostImpactfulMentor = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'disabled',
  },
];

const reportBasedSchedulingOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'report-based-scheduling-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'report-based-scheduling-disabled',
  },
];

export default FormsScreen;
