import { AxiosError } from 'axios';
import { toDate } from 'date-fns-tz';
import DOMPurify from 'dompurify';
import { ValidDate } from '../../pages/Advisors/Availabilities/types/ValidDate';
import { formatDateToRFC } from '../../utils/date';
import { axiosRequest } from '../instance';
import { Specialization, SpecializationId } from '../specializations';
import { TenantId } from '../tenants/Tenant';
import { TenantTimeZone } from '../tenants/types/Settings';
import { periodFromRecord, toString } from '../types/Period';
import { fromDate } from '../types/TenantRFCDate';
import {
  Advisor,
  AdvisorId,
  AdvisorWithSpecializations,
  Create,
  Update,
} from './types/Advisor';
import { AdvisorLog } from './types/AdvisorLog';
import {
  ApiAdvisor,
  ApiAdvisorCreate,
  ApiAdvisorUpdate,
  ApiAdvisorWithSpecializations,
} from './types/ApiAdvisor';
import { Availability } from './types/Availability';
import { SpecializationPair } from './types/SpecializationPair';
import { Status } from './types/Status';

function advisorToApi(
  v: Advisor | Create | Update,
  tenantTimeZone: TenantTimeZone,
): ApiAdvisor | ApiAdvisorCreate | ApiAdvisorUpdate {
  return {
    ...v,
    advisorAvailabilities:
      v.advisorAvailabilities?.map((i) => ({
        ...i,
        startTime: fromDate(tenantTimeZone, i.startTime),
        endTime: fromDate(tenantTimeZone, i.endTime),
        schedulingPeriod: toString(i.schedulingPeriod),
      })) ?? null,
    dateOfBirth: v.dateOfBirth ? formatDateToRFC(v.dateOfBirth) : null,
    dateOfEnrollment: formatDateToRFC(v.dateOfEnrollment),
  };
}

const advisorFromApi = (v: ApiAdvisor, timeZone: TenantTimeZone): Advisor => {
  return {
    ...v,
    dateOfBirth: v.dateOfBirth ? new Date(v.dateOfBirth) : null,
    dateOfEnrollment: new Date(v.dateOfEnrollment),
    deactivationDate: v.deactivationDate ? new Date(v.deactivationDate) : null,
    advisorAvailabilities:
      v.advisorAvailabilities?.map(
        (i): Availability => ({
          id: i.id,
          schedulingPeriod: i.schedulingPeriod
            ? periodFromRecord(JSON.parse(i.schedulingPeriod)) ?? {
                periodType: '',
              }
            : { periodType: '' },
          advisorId: i.advisorId,
          endTime: toDate(i.endTime, { timeZone: timeZone }) as ValidDate,
          startTime: toDate(i.startTime, { timeZone: timeZone }) as ValidDate,
          tenantId: i.tenantId,
          creationDate: new Date(i.creationDate),
          valid: i.valid,
          position: i.position,
        }),
      ) ?? [],
  };
};

const advisorWithSpecializationsFromApi = (
  v: ApiAdvisorWithSpecializations,
  timeZone: TenantTimeZone,
): AdvisorWithSpecializations => ({
  ...advisorFromApi(v, timeZone),
  advisorSpecializations: v.advisorSpecializations,
});

export function get(
  timeZone: TenantTimeZone,
  page: number,
): Promise<Advisor[]> {
  return axiosRequest
    .get<ApiAdvisor[]>({
      url: `/advisors/pages/${page}`,
      credentials: true,
    })
    .then((v) => v.data.map((v) => advisorFromApi(v, timeZone)))
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function search(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  s: string,
): Promise<AdvisorWithSpecializations[]> {
  return axiosRequest
    .get<ApiAdvisorWithSpecializations[]>({
      url: `/public/tenants/${tenantId}/advisors/search/${encodeURIComponent(
        DOMPurify.sanitize(s),
      )}`,
    })
    .then((v) =>
      v.data.map((v) => advisorWithSpecializationsFromApi(v, timeZone)),
    )
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getByStatus(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  status: Status,
  page: number,
): Promise<AdvisorWithSpecializations[]> {
  return axiosRequest
    .get<ApiAdvisorWithSpecializations[]>({
      url: `/public/tenants/${tenantId}/advisors/statuses/${status}/pages/${page}`,
    })
    .then((v) =>
      v.data.map((v) => advisorWithSpecializationsFromApi(v, timeZone)),
    )
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getBySpecialization(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  specializationId: SpecializationId,
  page: number,
): Promise<AdvisorWithSpecializations[]> {
  return axiosRequest
    .get<ApiAdvisorWithSpecializations[]>({
      url: `/public/tenants/${tenantId}/advisors/specializations/${specializationId}/pages/${page}`,
    })
    .then((v) =>
      v.data.map((v) => advisorWithSpecializationsFromApi(v, timeZone)),
    )
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getById(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  advisorId: AdvisorId,
): Promise<AdvisorWithSpecializations> {
  return axiosRequest
    .get<ApiAdvisorWithSpecializations>({
      url: `/public/tenants/${tenantId}/advisors/${advisorId}`,
    })
    .then((v) => advisorWithSpecializationsFromApi(v.data, timeZone));
}

export function getByEmail(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  email: string,
): Promise<Advisor[]> {
  return axiosRequest
    .get<ApiAdvisor[]>({
      url: `/public/tenants/${tenantId}/advisors/email/${email}`,
    })
    .then((v) => v.data.map((v) => advisorFromApi(v, timeZone)));
}

export function getGlobalByEmail(
  timeZone: TenantTimeZone,
  email: string,
): Promise<Advisor[]> {
  return axiosRequest
    .get<ApiAdvisor[]>({
      url: `/public/advisors/email/${email}`,
    })
    .then((v) => v.data.map((v) => advisorFromApi(v, timeZone)));
}

export function getByName(
  tenantId: TenantId,
  timeZone: TenantTimeZone,
  firstName: string,
  lastName: string,
): Promise<Advisor[]> {
  return axiosRequest
    .get<ApiAdvisor[]>({
      url: `/public/tenants/${tenantId}/advisors/lastname/${lastName}/firstname/${firstName}`,
    })
    .then((v) => v.data.map((v) => advisorFromApi(v, timeZone)));
}

export function remove(
  id: AdvisorId,
  timeZone: TenantTimeZone,
): Promise<Advisor> {
  return axiosRequest
    .delete<ApiAdvisor>({ url: `/advisors/${id}`, credentials: true })
    .then((v) => v.data)
    .then((v) => advisorFromApi(v, timeZone));
}

export function create(
  create: Create,
  tenantTimeZone: TenantTimeZone,
): Promise<Advisor> {
  return axiosRequest
    .post<ApiAdvisor>({
      url: `/advisors`,
      data: advisorToApi(create, tenantTimeZone),
      credentials: true,
    })
    .then((v) => v.data)
    .then((v) => advisorFromApi(v, tenantTimeZone));
}

export function update(
  patch: Update,
  tenantTimeZone: TenantTimeZone,
): Promise<Advisor> {
  return axiosRequest
    .put<ApiAdvisor>({
      url: `/advisors`,
      data: advisorToApi(patch, tenantTimeZone),
      credentials: true,
    })
    .then((v) => v.data)
    .then((v) => advisorFromApi(v, tenantTimeZone));
}

export function updateAvatar(
  id: AdvisorId,
  timeZone: TenantTimeZone,
  logoFile: File,
): Promise<Advisor> {
  let formData = new FormData();
  formData.append('name', logoFile.name);
  formData.append('file', logoFile);

  return axiosRequest
    .post<ApiAdvisor>({
      url: `/advisors/${id}/logo`,
      data: formData,
      credentials: true,
    })
    .then((r) => r.data)
    .then((v) => advisorFromApi(v, timeZone));
}

export function getAllSpecializations(
  tenantId: TenantId,
): Promise<Specialization[]> {
  return axiosRequest
    .get<Specialization[]>({
      url: `/public/tenants/${tenantId}/advisors/specializations`,
    })
    .then((v) => v.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getSpecializations(
  advisorId: AdvisorId,
): Promise<Specialization[]> {
  return axiosRequest
    .get<Specialization[]>({
      url: `/advisors/${advisorId}/specializations`,
      credentials: true,
    })
    .then((v) => v.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getAdvisorLogs(advisorId: AdvisorId): Promise<AdvisorLog[]> {
  return axiosRequest
    .get<AdvisorLog[]>({
      url: `/advisors/${advisorId}/log`,
      credentials: true,
    })
    .then((v) => v.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function addSpecialization(
  advisorId: AdvisorId,
  specializationId: SpecializationId,
): Promise<SpecializationPair> {
  return axiosRequest
    .post<SpecializationPair>({
      url: `/advisors/${advisorId}/specializations/${specializationId}`,
      credentials: true,
    })
    .then((v) => v.data);
}

export function removeSpecialization(
  advisorId: AdvisorId,
  specializationId: SpecializationId,
): Promise<SpecializationPair> {
  return axiosRequest
    .delete<SpecializationPair>({
      url: `/advisors/${advisorId}/specializations/${specializationId}`,
      credentials: true,
    })
    .then((v) => v.data);
}

export function getPublicSpecializations(
  tenantId: TenantId,
  advisorId: AdvisorId,
): Promise<Specialization[]> {
  return axiosRequest
    .get<Specialization[]>({
      url: `/public/tenants/${tenantId}/advisors/${advisorId}/specializations`,
    })
    .then((v) => v.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function getLogos(tenantId: TenantId, ids: string[]): Promise<string[]> {
  return axiosRequest
    .post<string[]>({
      url: `/public/tenants/${tenantId}/advisors/logos`,
      data: ids,
    })
    .then((v) => v.data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        return [];
      }

      throw e;
    });
}

export function archive(ids: AdvisorId[]): Promise<AdvisorId[]> {
  return axiosRequest
    .post<AdvisorId[]>({
      url: `/advisors/archives`,
      data: ids,
      credentials: true,
    })
    .then((v) => v.data);
}

export function unarchive(ids: AdvisorId[]): Promise<AdvisorId[]> {
  return axiosRequest
    .delete<AdvisorId[]>({
      url: `/advisors/archives`,
      data: ids,
      credentials: true,
    })
    .then((v) => v.data);
}
