import { makeStyles, Typography } from '@material-ui/core';
import Bowser from 'bowser';
import cn from 'classnames';
import { MentorStatus } from '../../api/mentors';
import { isTablet } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Toggler } from '../common';
import Actions, { Action } from './actions';

interface HeaderProps {
  title: string;
  status?: MentorStatus;
  statuses?: Array<{
    label: string;
    value: MentorStatus;
  }>;
  backLink: React.ReactNode;
  additional?: React.ReactNode;
  onStatusChange?: (status: MentorStatus) => any;
  actionRenderer?: () => any;
  prevPath?: string;
  isShowToggler?: boolean;
  additionalContent?: React.ReactNode;
  actions?: Action[];
  isMergedActions?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 28,
  },
  containerTablet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    marginBottom: 10,
    rowGap: 10,
    padding: '0 15px',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
      marginBottom: 28,
    },
  },
  activeTablet: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeButtonTablet: {
    display: 'flex',
  },
  title: {
    marginLeft: 5,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',

    '& h2': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      [theme.breakpoints.down('xs')]: {
        fontSize: '22px',
        lineHeight: '40px',
        whiteSpace: 'normal',
      },
    },
  },
  subtitle: {
    margin: '5px 0 0 20px',
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

const defaultStatuses: Array<{
  label: string;
  value: MentorStatus;
  className: string;
}> = [
  {
    label: 'Active',
    value: MentorStatus.ACTIVE,
    className: CLASS_TRACKING.INTERNAL_ACTION,
  },
  {
    label: 'Archived',
    value: MentorStatus.ARCHIVED,
    className: CLASS_TRACKING.INTERNAL_ACTION,
  },
];

function Header({
  title,
  status,
  statuses = defaultStatuses,
  onStatusChange,
  backLink,
  actionRenderer = () => {},
  additional,
  isShowToggler = true,
  additionalContent,
  actions,
  isMergedActions,
}: HeaderProps) {
  const classes = useStyles();
  const browser = Bowser.getParser(window.navigator.userAgent);

  const isTabletVersion =
    browser?.getPlatform().type === 'tablet' || isTablet();

  if (isTabletVersion) {
    return (
      <div className={cn(classes.containerTablet, classes.noPrint)}>
        <div className={classes.activeTablet}>
          {backLink}
          <div className={classes.title}>
            <Typography variant='h2'>{title}</Typography>
            <div className={classes.subtitle}>{additional}</div>
          </div>
          <div className={classes.activeButtonTablet}>
            {isShowToggler &&
              !!status &&
              typeof onStatusChange === 'function' && (
                <Toggler
                  value={status}
                  options={statuses}
                  onChange={onStatusChange}
                />
              )}

            {additionalContent}
            {actionRenderer()}
            {actions && (
              <Actions actions={actions} isMerged={isMergedActions} />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(classes.container, classes.noPrint)}>
      {backLink}
      <div className={classes.title}>
        <Typography variant='h2'>{title}</Typography>
        <div className={classes.subtitle}>{additional}</div>
      </div>

      {isShowToggler && !!status && typeof onStatusChange === 'function' && (
        <Toggler value={status} options={statuses} onChange={onStatusChange} />
      )}
      {actions && <Actions actions={actions} isMerged={isMergedActions} />}
      {actionRenderer()}
    </div>
  );
}

export { Header };
