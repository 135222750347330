import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import authAPI from '../../api/auth';
import { PageLoader } from '../../components/common';
import FormsScreen from '../../components/tenant/forms-screen';
import { UserContext } from '../../contexts/user-context';

const useStyles = makeStyles({
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function TenantFormsPage() {
  const classes = useStyles();
  const { user: tenant } = useContext(UserContext);
  const tenantId = useMemo(() => tenant?.id, [tenant]);
  const [links, setLinks] = useState({
    mentors: '',
    ventures: '',
  });

  const loadLinks = async (tenantId: string) => {
    const [mentorLink, ventureLink] = await Promise.all([
      authAPI.getMentorApplicationLink(tenantId),
      authAPI.getVentureApplicationLink(tenantId),
    ]);
    setLinks({
      mentors: mentorLink,
      ventures: ventureLink,
    });
  };

  useEffect(() => {
    if (tenantId) {
      loadLinks(tenantId);
    }
  }, [tenantId]);

  if (!links.mentors || !links.ventures) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return <FormsScreen links={links} />;
}

export default TenantFormsPage;
