import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authAPI from '../../api/auth';
import specializationsAPI from '../../api/specializations';
import { Role } from '../../api/user/Role';
import { TestId } from '../../components/Testing/TestId';
import { SnackMessage, Text, Toggler } from '../../components/common';
import { WithRole } from '../../components/common/WithRole';
import { WithRule } from '../../components/common/WithRule';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { SpecializationContext } from '../../contexts/specialization-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import TenantAccordion from './tenant-accordion';

interface CustomSpecializationsSettingsProps {
  expanded?: boolean;
  onExpand?: () => void;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 670,

    '@media (max-width: 700px)': {
      marginLeft: -40,
      marginRight: -40,
    },
  },
  title: {
    margin: '0 0 20px',

    '@media (max-width: 700px)': {
      marginLeft: 16,
    },
  },
  subtitle: {
    margin: '20px 0',
  },
  button: {
    display: 'flex',
    margin: '10px 0',
  },
});

function CustomSpecializationsSettings({
  expanded,
  onExpand,
}: CustomSpecializationsSettingsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { loadSpecializations } = useContext(SpecializationContext);

  const [value, setValue] = useState<'on' | 'off'>('off');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showGear = !isLoading && value === 'on';

  const handleChangeCustomSpecializations = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (value) {
        await specializationsAPI.enableCustomSpecializations();
      } else {
        await specializationsAPI.disableCustomSpecializations();
      }

      await loadSpecializations();
      setValue(val);
      const message = value
        ? 'Custom Specializations are enabled'
        : 'Custom Specializations are disabled';
      enqueueSnackbar(message, {
        variant: 'success',
      });
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating custom specializations module. Please, try again.'
        : 'An error occurred while deactivating custom specializations module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const getStatus = async () => {
      try {
        setIsLoading(true);
        const status =
          await specializationsAPI.getCustomSpecializationsStatus();
        setValue(status ? 'on' : 'off');
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getStatus();
  }, []);

  return (
    <TenantAccordion
      expanded={expanded}
      onExpand={onExpand}
      summary={
        <>
          <Typography variant='h5'>Specialization Tags</Typography>
          <TestId testId={'custom-specializations-enable'}>
            <Toggler<'on' | 'off'>
              value={value}
              options={customSpecializationsOptions}
              onChange={handleChangeCustomSpecializations}
              disabled={isLoading}
              withIcon={false}
              mini
            />
          </TestId>
        </>
      }
      actions={
        showGear ? (
          <Tooltip title='Custom Specialization Settings'>
            <IconButton
              component={Link}
              to={Pages.TENANT_CUSTOM_SPECIALIZATIONS}
              data-testid='custom-specializations-page'>
              <Settings />
            </IconButton>
          </Tooltip>
        ) : null
      }>
      <div>
        <Text variant='normal'>
          Custom Specializations are experience tags that highlight the
          expertise of experts in your program, making them easy to search and
          reference. You can edit existing specializations or add new ones by
          clicking on the gear icon.
        </Text>
      </div>
    </TenantAccordion>
  );
}

function TenantSettingsPage() {
  const classes = useStyles();
  const { user, updateUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const handleChangeModuleStatus = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          quarterlyReportEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Automated quarterly reports module is enabled'
          : 'Automated quarterly reports module is disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating automated quarterly reports module. Please, try again.'
        : 'An error occurred while deactivating automated quarterly reports module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeOfficeHours = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          strictOfficeHoursScheduling: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Strict office hours booking is enabled'
          : 'Strict office hours booking is disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating strict office hours booking module. Please, try again.'
        : 'An error occurred while deactivating strict office hours booking module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeSecondFactorAuth = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          secondFactorEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Second Factor Authentication is enabled'
          : 'Second Factor Authentication is disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating Second Factor Authentication module. Please, try again.'
        : 'An error occurred while deactivating Second Factor Authentication module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const handleChangeGroupStatistics = async (val: 'on' | 'off') => {
    const value = toggleToBool(val);
    try {
      if (user) {
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          groupStatisticsEnabled: value,
          timeZone: user.timeZone ?? 'US/Eastern',
        });
        updateUser(updatedTenant);
        const message = value
          ? 'Group statistics are enabled'
          : 'Group statistics are disabled';
        enqueueSnackbar(message, {
          variant: 'success',
        });
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? 'An error occurred while activating group statistics module. Please, try again.'
        : 'An error occurred while deactivating group statistics module. Please, try again.';

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  const onExpand = useCallback(
    (id: string) => () =>
      setExpandedSection((prev) => (prev === id ? null : id)),
    [],
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant='h4'>
        Optional modules
      </Typography>
      <WithRole roles={[Role.Admin, Role.Manager]}>
        <TenantAccordion
          expanded={expandedSection === 'automation'}
          onExpand={onExpand('automation')}
          summary={<Typography variant='h5'>Automation Workflows</Typography>}
          actions={
            <Tooltip title='Module automation'>
              <IconButton
                component={Link}
                to={Pages.TENANT_AUTOMATION}
                data-testid='automation-page'>
                <Settings />
              </IconButton>
            </Tooltip>
          }>
          <div>
            <Text variant='normal'>
              Traction5, by default, automates numerous aspects of program
              management to save time and ensure smooth operation.
              Simultaneously, we provide the flexibility to disable some or all
              automations based on your specific needs.
            </Text>
          </div>
        </TenantAccordion>

        <TenantAccordion
          expanded={expandedSection === 'cohorts'}
          onExpand={onExpand('cohorts')}
          summary={<Typography variant='h5'>Venture Groups</Typography>}
          actions={
            <Tooltip title='Module groups'>
              <IconButton
                component={Link}
                to={Pages.TENANT_COHORTS}
                data-testid='cohorts-page'>
                <Settings />
              </IconButton>
            </Tooltip>
          }>
          <div>
            <Text variant='normal'>
              Venture Groups allow program admins to combine ventures registered
              on Traction5 platform into logical groups. Although these groups
              do not have any functional impact, they can be used to further
              enhance processes owned by admin, e.g. onboarding or scheduling.
            </Text>
          </div>
        </TenantAccordion>
      </WithRole>
      <WithRule rule={'modules.reports.view'}>
        <TenantAccordion
          expanded={expandedSection === 'reports'}
          onExpand={onExpand('reports')}
          summary={
            <>
              <Typography variant='h5'>Automated Quarterly Reports</Typography>
              <TestId testId={'report-enable'}>
                <Toggler<'on' | 'off'>
                  value={boolToToggle(user?.quarterlyReportEnabled ?? false)}
                  options={quarterlyModuleStatuses}
                  onChange={handleChangeModuleStatus}
                  withIcon={false}
                  mini
                />
              </TestId>
            </>
          }
          actions={
            <Tooltip title='Module Settings'>
              <IconButton
                component={Link}
                to={Pages.TENANT_QUESTIONS}
                data-testid='questions-page'>
                <Settings />
              </IconButton>
            </Tooltip>
          }>
          <div>
            <Text variant='normal'>
              When enabled, Traction5 will send out automated requests to
              founders on January 1, April 1, July 1, and October 1 to submit
              updates on the number of full-time employees, revenue generated
              over the last 3 months, funds raised, and likelihood the founders
              will recommend the
              {rb('mentorship')} program to peers.
            </Text>
          </div>
        </TenantAccordion>
      </WithRule>
      <WithRole roles={[Role.Admin, Role.Manager]}>
        <TenantAccordion
          expanded={expandedSection === 'office-hours'}
          onExpand={onExpand('office-hours')}
          summary={
            <>
              <Typography variant='h5'>Office Hours Access Control</Typography>
              <TestId testId={'office-hours-enable'}>
                <Toggler<'on' | 'off'>
                  value={boolToToggle(
                    user?.strictOfficeHoursScheduling ?? false,
                  )}
                  options={officeHoursStatuses}
                  onChange={handleChangeOfficeHours}
                  withIcon={false}
                  mini
                />
              </TestId>
            </>
          }>
          <div>
            <Text variant='normal'>
              <a
                target='_blank'
                rel='noreferrer'
                href={getRoutePath(Pages.PUBLIC_OH_LANDING, {
                  tenantId: user?.id,
                })}>
                Here is the the link to the public page of Office Hours
              </a>
              . When registration and approval requirement is turned on,
              visitors to Office Hours will need to register and be approved by
              administrator before they are able to book advisors.
            </Text>
            <br />
            <br />
            <Text variant='normal'>
              Not Needed option means that Office Hours visitors can book
              advisors without registration. They will still be added to
              Traction5 as applicants, and their meetings with advisors will be
              tracked for reporting proposes.
            </Text>
          </div>
        </TenantAccordion>

        <TenantAccordion
          expanded={expandedSection === 'second-factor-auth'}
          onExpand={onExpand('second-factor-auth')}
          summary={
            <>
              <Typography variant='h5'>Second Factor Authentication</Typography>
              <TestId testId={'second-factor-enable'}>
                <Toggler<'on' | 'off'>
                  value={boolToToggle(user?.secondFactorEnabled ?? false)}
                  options={secondFactorAuthOptions}
                  onChange={handleChangeSecondFactorAuth}
                  withIcon={false}
                  mini
                />
              </TestId>
            </>
          }>
          <div>
            <Text variant='normal'>
              Second Factor authentication enables enhanced security for program
              members. Additional random code is sent to user email during the
              login process to confirm user identity.
            </Text>
          </div>
        </TenantAccordion>

        <TenantAccordion
          expanded={expandedSection === 'group-statistics'}
          onExpand={onExpand('group-statistics')}
          summary={
            <>
              <Typography variant='h5'>Group Statistics</Typography>
              <TestId testId={'group-statistics-enable'}>
                <Toggler<'on' | 'off'>
                  value={boolToToggle(user?.groupStatisticsEnabled ?? false)}
                  options={groupStatisticsOptions}
                  onChange={handleChangeGroupStatistics}
                  withIcon={false}
                  mini
                />
              </TestId>
            </>
          }>
          <div>
            <Text variant='normal'>
              This setting will enable generation of {rb('mentor')}{' '}
              participation statistics that is based on existing groups. This
              high-level statistics will be shown in {rb('mentor')} list and
              contain a break-down of {rb('mentor')} participation by groups
              they are assigned to.
            </Text>
          </div>
        </TenantAccordion>
        <CustomSpecializationsSettings
          expanded={expandedSection === 'custom-specializations'}
          onExpand={onExpand('custom-specializations')}
        />
      </WithRole>
      {/* {hasAccessToModal(KnownModule.Slack) && (
        <WithRule rule={'modules.reports.view'}>
          <Typography className={classes.subtitle} variant='h5'>
            Slack Integration
          </Typography>
          <TestId testId={'slack-integration'}>
            <SlackIntegrationButton className={classes.button} />
          </TestId>
          <div>
            <Text variant='normal'>
              When Slack integration is enabled you will start receiving all
              important program notifications in a selected slack channel so
              that you can react to those events in near-real-time fashion. When
              you click on a button you will be forwarded to a Slack workspace
              that will request your permission to install Traction5 Slack App
              into your Slack workspace. You must have permissions to install
              Slack Apps in order to complete the integration process.
            </Text>
          </div>
        </WithRule>
      )} */}
    </div>
  );
}

export default TenantSettingsPage;

const quarterlyModuleStatuses = [
  { label: 'Enabled', value: 'on' as const },
  { label: 'Disabled', value: 'off' as const },
];

const officeHoursStatuses = [
  {
    label: 'Authorized Only',
    value: 'on' as const,
    testId: 'authorized-only',
  },
  { label: 'Open to Public', value: 'off' as const, testId: 'open-to-public' },
];

const secondFactorAuthOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'second-factor-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'second-factor-disabled',
  },
];

const groupStatisticsOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'group-statistics-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'group-statistics-disabled',
  },
];

const customSpecializationsOptions = [
  {
    label: 'Enabled',
    value: 'on' as const,
    testId: 'custom-specializations-enabled',
  },
  {
    label: 'Disabled',
    value: 'off' as const,
    testId: 'custom-specializations-disabled',
  },
];

function boolToToggle(v: boolean): 'on' | 'off' {
  switch (v) {
    case true:
      return 'on';
    case false:
      return 'off';
  }
}

function toggleToBool(v: 'on' | 'off'): boolean {
  switch (v) {
    case 'on':
      return true;
    case 'off':
      return false;
  }
}
