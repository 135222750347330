import {
  CircularProgress,
  Link as MaterialLink,
  makeStyles,
} from '@material-ui/core';
import cn from 'classnames';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { SyntheticEvent, useContext, useEffect, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import { GeneralReportContext } from '../../contexts/general-report';
import { QuarterlyReportContext } from '../../contexts/quarterly-report';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import {
  formatDate,
  formatDateToRFC,
  getDateForGeneralReportList,
  getDateForReportList,
} from '../../utils/date';
import { Link } from '../common';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '24px 32px 28px',
    boxSizing: 'border-box',
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  date: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  summary: {
    paddingRight: 20,
    paddingLeft: 20,
    boxSizing: 'border-box',
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeadRow: {
    borderTop: 'none',
  },
  dash: {
    color: COLORS.COLOR_BLUE_DARKENED_10,
  },
  scrollable: {
    maxHeight: 53,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

interface DateMonth {
  start: Date;
  end: Date;
}

const getStartAndEndMonth = (date: Date): DateMonth => {
  return {
    start: startOfMonth(date),
    end: endOfMonth(date),
  };
};

const getLast3Months = () => {
  let months: DateMonth[] = [];
  const today = new Date();
  let i = 1;
  while (i <= 3) {
    const month = getStartAndEndMonth(addMonths(today, -i));
    months.push(month);
    i++;
  }
  return months;
};

const getPeriodDate = (date: Date): DateMonth => {
  const today = new Date();

  return {
    start: startOfMonth(date),
    end: endOfMonth(addMonths(today, -1)),
  };
};

const getDateForMentor = (periods: number[]) => {
  let months: DateMonth[] = [];
  const today = new Date();

  periods.map((period) => {
    const month = getPeriodDate(addMonths(today, -period));
    return months.push(month);
  });
  return months;
};

const getDatesRange30Days = () => {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);

  return {
    endDate: formatDateToRFC(endDate),
    startDate: formatDateToRFC(startDate),
  };
};

const getDatesRange12Month = () => {
  const today = new Date();
  const month = getPeriodDate(addMonths(today, -12));

  return {
    startDate: formatDateToRFC(month.start),
    endDate: formatDateToRFC(month.end),
  };
};

function ReportsListScreen() {
  const classes = useStyles();
  const location = useLocation();
  const { reportDates, isLoadingDates, loadDates } =
    useContext(GeneralReportContext);
  const { reportQuarters, isLoadingQuarters, loadQuarters } = useContext(
    QuarterlyReportContext,
  );
  const { rb } = useResourceBundles();

  const REPORTS_LIST = useMemo(
    () => [
      {
        name: 'Venture progress report',
        description: 'Summary of progress of each venture for the last 30 days',
        testid: 'venture-progress',
        alias: 'venture-progress',
        url: Pages.REPORTS_DETAILS_VENTURE,
      },
      {
        name: `${rb('mentor-u')} attendance report`,
        description: `${rb(
          'mentor-u',
        )} session attendance for the last 12 month`,
        testid: 'mentor-progress',
        alias: 'mentor-progress',
        url: Pages.REPORTS_DETAILS_MENTOR,
      },
      {
        name: 'General program report',
        description: 'Contains key program demographics',
        testid: 'general-progress',
        alias: 'general-progress',
        url: Pages.REPORTS_GENERAL,
      },
      {
        name: 'Quarterly venture reports',
        description: 'Summary of venture quarterly reporting',
        testid: 'quarterly-progress',
        alias: 'quarterly-progress',
        url: Pages.REPORTS_QUARTERLY,
      },
    ],
    [rb],
  );

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const { width: containerWidth, ref: containerRef } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
  });
  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const getTableCellDate = (alias: string) => {
    switch (alias) {
      case 'venture-progress': {
        const dates = getLast3Months();
        return (
          <TableCell>
            {dates.map((date, index) => {
              return (
                <>
                  <span className={classes.dash}>{index !== 0 && ' - '}</span>
                  <MaterialLink
                    data-testid={`reports-table-date-${index}`}
                    onClick={stopPropagation}
                    component={Link}
                    to={{
                      pathname: Pages.REPORTS_DETAILS_VENTURE,
                      state: {
                        prevPath: currentPath,
                        start: date.start,
                        end: date.end,
                      },
                    }}>
                    {getDateForReportList(date.start)}
                  </MaterialLink>
                </>
              );
            })}
          </TableCell>
        );
      }
      case 'general-progress': {
        const dates = reportDates || [];

        return (
          <TableCell className={classes.scrollable}>
            {isLoadingDates ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              dates.map((date, index) => {
                return (
                  <>
                    <span className={classes.dash}>{index !== 0 && ' - '}</span>
                    <MaterialLink
                      data-testid={`reports-table-date-general-${index}`}
                      onClick={stopPropagation}
                      component={Link}
                      to={{
                        pathname: Pages.REPORTS_GENERAL,
                        search: `?date=${date}`,
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      {getDateForGeneralReportList(date)}
                    </MaterialLink>
                  </>
                );
              })
            )}
          </TableCell>
        );
      }
      case 'quarterly-progress': {
        const dates = reportQuarters || [];

        return (
          <TableCell>
            {isLoadingQuarters ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              dates.map((date, index) => {
                return (
                  <>
                    <span className={classes.dash}>{index !== 0 && ' - '}</span>
                    <MaterialLink
                      data-testid={`reports-table-date-quarterly-${index}`}
                      onClick={stopPropagation}
                      component={Link}
                      to={{
                        pathname: Pages.REPORTS_QUARTERLY,
                        search: `?quarter=${date.quarter}&year=${date.year}`,
                        state: {
                          prevPath: currentPath,
                        },
                      }}>
                      Q{date.quarter} {date.year}
                    </MaterialLink>
                  </>
                );
              })
            )}
          </TableCell>
        );
      }
      default: {
        const periods = [3, 6],
          periodsDates = getDateForMentor(periods);
        return (
          <TableCell>
            {periodsDates.map((date, index) => {
              return (
                <>
                  <span className={classes.dash}>{index !== 0 && ' - '}</span>
                  <MaterialLink
                    data-testid={`reports-table-date-${index}`}
                    onClick={stopPropagation}
                    component={Link}
                    to={{
                      pathname: Pages.REPORTS_DETAILS_MENTOR,
                      search: `?startDate=${formatDate(
                        date.start,
                      )}&endDate=${formatDate(date.end)}`,
                      state: {
                        prevPath: currentPath,
                      },
                    }}>
                    {periods[index]} months
                  </MaterialLink>
                </>
              );
            })}
          </TableCell>
        );
      }
    }
  };

  useEffect(() => {
    if (!reportDates) {
      loadDates();
    }
  }, [reportDates, loadDates]);

  useEffect(() => {
    if (!reportQuarters) {
      loadQuarters();
    }
  }, [reportQuarters, loadQuarters]);

  return (
    <div
      ref={containerRef}
      className={classes.container}
      data-testid='reports-page'>
      {REPORTS_LIST.length > 0 && (
        <div>
          <Table
            gridClassName={classes.table}
            headerHeight={56}
            height={
              64 * (REPORTS_LIST.length > 10 ? REPORTS_LIST.length : 10) + 56
            }
            rowHeight={64}
            rowCount={REPORTS_LIST.length}
            rowGetter={({ index }) => REPORTS_LIST[index]}
            headerRowRenderer={(headRowProps) => (
              <TableHeadRow
                {...headRowProps}
                className={classes.tableHeadRow}
              />
            )}
            rowRenderer={(rowProps) => (
              <TableRow
                data-testid={`reports-table-row-${rowProps.index}`}
                {...rowProps}
              />
            )}
            width={containerWidth || 500}>
            <Column
              dataKey='name'
              className={cn(classes.column, classes.summary)}
              headerClassName={classes.summary}
              width={180}
              minWidth={180}
              flexGrow={1}
              headerRenderer={() => <TableHeadCell>Report Name</TableHeadCell>}
              cellRenderer={({ cellData, rowData }) => {
                const dates =
                  rowData.alias === 'mentor-progress'
                    ? getDatesRange12Month()
                    : getDatesRange30Days();

                const currentUrl =
                  rowData.alias === 'mentor-progress'
                    ? {
                        pathname: rowData.url,
                        search: `?startDate=${formatDate(
                          dates.startDate,
                        )}&endDate=${formatDate(dates.endDate)}`,
                        state: {
                          prevPath: currentPath,
                        },
                      }
                    : {
                        pathname: rowData.url,
                        state: {
                          prevPath: currentPath,
                          start: dates.startDate,
                          end: dates.endDate,
                        },
                      };
                getRoutePath(rowData.url, {
                  startDate: formatDate(dates.startDate),
                  endDate: formatDate(dates.endDate),
                });

                return (
                  <div className={classes.summaryContainer}>
                    <TableCell truncated>
                      <MaterialLink
                        data-testid={`reports-table-name-${rowData.testid}`}
                        onClick={stopPropagation}
                        component={Link}
                        to={currentUrl}>
                        {cellData}
                      </MaterialLink>
                    </TableCell>
                  </div>
                );
              }}
            />

            <Column
              dataKey='description'
              className={cn(classes.column, classes.date)}
              headerClassName={classes.date}
              width={204}
              minWidth={204}
              maxWidth={400}
              flexGrow={1}
              headerRenderer={() => <TableHeadCell>Description</TableHeadCell>}
              cellRenderer={({ cellData }) => {
                return <TableCell>{cellData}</TableCell>;
              }}
            />

            <Column
              dataKey='date'
              className={cn(classes.column, classes.date)}
              headerClassName={classes.date}
              width={204}
              minWidth={204}
              maxWidth={400}
              flexGrow={1}
              headerRenderer={() => (
                <TableHeadCell>Frequently Used Periods</TableHeadCell>
              )}
              cellRenderer={({ rowData }) => {
                return getTableCellDate(rowData.alias);
              }}
            />
          </Table>
        </div>
      )}
    </div>
  );
}

export default ReportsListScreen;
