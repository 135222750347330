import { Button as MaterialButton, ButtonProps } from '@material-ui/core';
import { forwardRef } from 'react';

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & { component?: any; target?: string }
>((props, ref) => {
  return (
    <MaterialButton ref={ref} variant='contained' color='primary' {...props} />
  );
});

export default Button;
