import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getAllCustomQuestions } from '../../../api/CustomQuestion';
import { Audience } from '../../../api/CustomQuestion/types/Audience';
import { CustomQuestionType } from '../../../api/CustomQuestion/types/Type';
import authAPI from '../../../api/auth';
import { TestId } from '../../../components/Testing/TestId';
import { SnackMessage, Text, Toggler } from '../../../components/common';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { UserContext } from '../../../contexts/user-context';
import { getRoutePath, Pages } from '../../../router/constants';
import TenantAccordion from '../tenant-accordion';
import { customQuestionsBlocksSettings } from './custom-question-blocks-settings';

type DefaultCustomType = 'default' | 'custom';

interface ICustomOptions {
  label: string;
  value: DefaultCustomType;
  testId?: string;
}

interface TenantCustomQuestionsBlockProps {
  audience: Audience;
  expanded?: boolean;
  onExpand?: () => void;
}

function TenantCustomQuestionsBlock({
  audience,
  expanded,
  onExpand,
}: TenantCustomQuestionsBlockProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();
  const { user, updateUser } = useContext(UserContext);
  const { key, title, tooltip, testId, text } =
    customQuestionsBlocksSettings[audience];
  const state = user && user[key] ? 'custom' : 'default';

  const handleChangeQuestionsType = async (value: DefaultCustomType) => {
    const booleanValue = value === 'custom';
    try {
      if (user) {
        const customQuestions = await getAllCustomQuestions(user.id, audience);
        const customQuestionsWithoutSubtitles = customQuestions.filter(
          (question) => question.type !== CustomQuestionType.SUBTITLE,
        );
        if (!customQuestionsWithoutSubtitles.length && booleanValue) {
          enqueueSnackbar(
            'Please note that you cannot switch to custom mode until you have custom questions available.',
            {
              variant: 'error',
            },
          );
          return;
        }
        const updatedTenant = await authAPI.updateTenant(user?.id, {
          ...user,
          [key]: booleanValue,
        });
        updateUser(updatedTenant);
        const message = value
          ? `${rb(title)} ${value} activated.`
          : `${rb(title)} ${value} deactivated.`;
        enqueueSnackbar(message, {
          variant: 'success',
        });
      } else {
        throw new Error('User is not defined');
      }
    } catch (e: any) {
      const messageError = e.response?.data?.message;
      const message = value
        ? `${rb(title)} ${value} activation failed.`
        : `${rb(title)} ${value} deactivation failed.`;

      enqueueSnackbar(message, {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
      });
    }
  };

  return (
    <TenantAccordion
      expanded={expanded}
      onExpand={onExpand}
      summary={
        <>
          <Typography variant='h5'>{rb(title)}</Typography>
          <TestId testId={`${testId}-toggler`}>
            <Toggler<DefaultCustomType>
              value={state}
              options={defaultCustomOptions}
              onChange={handleChangeQuestionsType}
              withIcon={false}
              mini
            />
          </TestId>
        </>
      }
      actions={
        <Tooltip title={rb(tooltip)}>
          <IconButton
            component={Link}
            to={getRoutePath(Pages.TENANT_CUSTOM_QUESTIONS_EDIT, {
              audience,
            })}
            data-testid={testId}>
            <Settings />
          </IconButton>
        </Tooltip>
      }>
      <Text variant='normal'>{rb(text)}</Text>
    </TenantAccordion>
  );
}

export default TenantCustomQuestionsBlock;

const defaultCustomOptions: ICustomOptions[] = [
  { label: 'Default', value: 'default', testId: 'default-option' },
  { label: 'Custom', value: 'custom', testId: 'custom-option' },
];
