import {
  IconButton,
  Paper,
  Tooltip,
  Button as MaterialButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Link,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Role } from '../../api/user/Role';
import venturesAPI from '../../api/ventures';
import { Status, Venture } from '../../api/ventures/types/Venture';
import {
  Button,
  ConfirmButton,
  PageLoader,
  RouteACL,
  SnackMessage,
} from '../../components/common';
import { CreateSchedulingWizardButton } from '../../components/common/create-scheduling-wizard-button';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import PageTabs from '../../components/ventures/page-tabs';
import { UserContext } from '../../contexts/user-context';
import {
  VentureContext,
  VentureProvider,
} from '../../contexts/venture-context';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import VentureAdditionalInfoPage from './venture-additional-info-page';
import VentureAssignmentsPage from './venture-assignments-page';
import VentureDetailsPage from './venture-details-page';
import VentureFoundersPage from './venture-founders-page';
import VentureLogPage from './venture-log-page';
import VentureNotesPage from './venture-notes-page';
import VentureQuarterlyReportPage from './venture-quarterly-report-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  requestLink: {
    minWidth: 187,
    whiteSpace: 'nowrap',
  },
  newSessionButton: {
    whiteSpace: 'nowrap',
    minWidth: 108,
  },
});

function VenturePage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { ventureId } = useParams<{ ventureId: Venture['id'] }>();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const { tokenData, hasAccessToAction, hasRole } = useContext(UserContext);
  const {
    venture,
    assignedMentors,
    ventureTags,
    loadVentureTags,
    isVentureLoading,
    ventureSpecializations,
    loadVenture,
    loadVentureSpecializations,
    updateVenture,
  } = useContext(VentureContext);
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const isMentorNotParticipant = useMemo(() => {
    const isMentor = hasRole(Role.Mentor);

    if (!isMentor) {
      return false;
    }

    const identityId = tokenData?.identityid;
    const loadedAssignedMentors = assignedMentors.type === 'Success';

    if (!identityId || !loadedAssignedMentors) {
      return true;
    }

    return assignedMentors.payload.data.every(
      (m) => m !== tokenData.identityid,
    );
  }, [assignedMentors, hasRole, tokenData?.identityid]);

  const handleStatusChange = useCallback(
    async (status: Status) => {
      if (venture) {
        const prevStatus = venture.status;
        try {
          updateVenture({ ...venture, status });
          await venturesAPI.update(venture.id, { ...venture, status });
          enqueueSnackbar(
            `The venture status was successfully changed to '${status}'`,
            {
              variant: 'success',
            },
          );
        } catch (e: any) {
          const messageError = e.response?.data?.message;

          updateVenture({ ...venture, status: prevStatus });
          enqueueSnackbar(
            "An error occurred while changing the venture's status. Please, try again.",
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          console.log('error status change', e);
        }
      }
    },
    [venture, updateVenture, enqueueSnackbar],
  );

  const handleSendVentureUpdate = async (onClose: () => any) => {
    try {
      setSending(true);
      await venturesAPI.sendVentureUpdateInvites([ventureId]);
      enqueueSnackbar('The profile update request was successfully sent', {
        variant: 'success',
      });
      setSending(false);
      onClose();
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setSending(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  // load venture's info
  useEffect(() => {
    if (!venture) {
      loadVenture(ventureId);
    }
  }, [ventureId, venture, loadVenture]);

  // load venture's specializations
  useEffect(() => {
    if (!ventureSpecializations) {
      loadVentureSpecializations(ventureId);
    }
  }, [ventureSpecializations, ventureId, loadVentureSpecializations]);

  // load venture's tags
  useEffect(() => {
    if (!ventureTags) {
      loadVentureTags(ventureId);
    }
  }, [ventureTags, ventureId, loadVentureTags]);

  return (
    <BaseLayout user={user} fullHeight>
      {isVentureLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title={venture?.ventureName || ''}
            onStatusChange={handleStatusChange}
            backLink={
              <Tooltip
                title='Back to Ventures'
                data-testid='button-back-ventures'>
                <IconButton
                  component={Link}
                  to={prevPath || Pages.ACTIVE_VENTURES}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            isMergedActions
            actions={[
              {
                key: 'new-session',
                label: 'New Session',
                hidden: !hasAccessToAction('session.create'),
                component: (
                  <MaterialButton
                    component={Link}
                    to={{
                      pathname: getRoutePath(Pages.NEW_SESSIONS),
                      state: {
                        ventureId: ventureId,
                        prevPath: currentPath,
                      },
                    }}
                    startIcon={<AddIcon />}
                    variant='contained'
                    color='primary'
                    className={classes.newSessionButton}
                    data-testid='button-create-session'>
                    Session
                  </MaterialButton>
                ),
              },
              {
                key: 'scheduling-wizard',
                label: 'Scheduling Wizard',
                hidden:
                  !hasAccessToAction('venture.wizard') ||
                  isMentorNotParticipant,
                component: (
                  <CreateSchedulingWizardButton
                    ventureId={ventureId}
                    ventureName={venture?.ventureName || ''}
                  />
                ),
              },
              {
                key: 'request-profile-update',
                label: 'Request Profile Update',
                hidden: !hasAccessToAction('venture.updateProfile'),
                component: (
                  <ConfirmButton
                    title='Send Single Profile Update Request'
                    loading={sending}
                    body='Your are about to send a temporary link to allow participants to update their profile. The link can work only 1 time, and is available for 48 hours only.'
                    successProps={{
                      btnLabel: 'Ok',
                      onSuccess: handleSendVentureUpdate,
                    }}
                    cancelProps={{
                      btnLabel: 'Cancel',
                    }}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        className={classes.requestLink}
                        onClick={onClick}
                        variant='contained'
                        data-testid='button-invite-venture-update'>
                        Request Profile Update
                      </Button>
                    )}
                  />
                ),
              },
            ]}
          />
          <Paper className={classes.container} elevation={1}>
            <PageTabs ventureId={ventureId} />
            <div className={classes.content}>
              <Switch>
                <RouteACL
                  exact
                  path={Pages.VENTURE_DETAILS}
                  component={VentureDetailsPage}
                  rule='venture.details.view'
                  redirectUrl={Pages.ACTIVE_VENTURES}
                />
                <RouteACL
                  exact
                  path={Pages.VENTURE_FOUNDERS}
                  component={VentureFoundersPage}
                  rule='venture.founders.view'
                  redirectUrl={Pages.ACTIVE_VENTURES}
                />
                <RouteACL
                  exact
                  path={Pages.VENTURE_ASSIGNMENTS}
                  component={VentureAssignmentsPage}
                  rule='venture.assignemnts.view'
                  redirectUrl={Pages.ACTIVE_VENTURES}
                />
                <RouteACL
                  exact
                  path={Pages.VENTURE_NOTES}
                  component={VentureNotesPage}
                  rule='venture.notes.view'
                  redirectUrl={Pages.ACTIVE_VENTURES}
                />
                <RouteACL
                  exact
                  path={Pages.VENTURE_LOG}
                  component={VentureLogPage}
                  redirectUrl={Pages.ACTIVE_VENTURES}
                  rule='venture.logs.view'
                />
                <RouteACL
                  exact
                  path={Pages.VENTURE_ADDITIONAL_INFO}
                  component={VentureAdditionalInfoPage}
                  rule='venture.additionalInfo.view'
                  redirectUrl={Pages.ACTIVE_VENTURES}
                />
                <RouteACL
                  exact
                  path={Pages.VENTURE_QUARTERLY_REPORT}
                  component={VentureQuarterlyReportPage}
                  rule='venture.finance.view'
                  redirectUrl={Pages.ACTIVE_VENTURES}
                />
                <Redirect to={Pages.VENTURE_DETAILS} />
              </Switch>
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

function withVentureProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <VentureProvider>
      <Component {...props} />
    </VentureProvider>
  );
}

export default withVentureProvider(VenturePage);
