import { formatDateApi } from '../utils/date';
import { axiosRequest } from './instance';
import { Mentor } from './mentors';
import { DashboardFoundersReport } from './types/FoundersDashbaord';
import { VentureNote } from './ventures';
import { Venture, VentureId } from './ventures/types/Venture';

export interface QuarterlyReportDate {
  quarter: number;
  year: number;
}

export interface QuarterlyReportRecord {
  id: string;
  tenantId: string;
  creationDate: string;
  quarterNumber: number;
  year: number;
  recordsNumber: number;
  missingRecordsNumber: number;
  ratingZero: number;
  ratingOne: number;
  ratingTwo: number;
  ratingThree: number;
  ratingFour: number;
  ratingFive: number;
  ratingSix: number;
  ratingSeven: number;
  ratingEight: number;
  ratingNine: number;
  ratingTen: number;
  detractors: number;
  passive: number;
  promoters: number;
  netPromotersScore: number;
  fte: number;
  revenue: number;
  equityRaised: number;
  grantsRaised: number;
  loans: number;
  crowdFunding: number;
  other: number;
  ventureQuarterlyReportRatingDetails: Array<{
    companyNames: string;
    ratingValue: number;
  }>;
}

interface DashboardReportResponse {
  mentorsActiveLastMonth: number;
  mentorsActive: number;
  mentorsOnboardedThisMonth: number;
  mentorsNotAssigned: number;
  mentorsOneVenture: number;
  mentorsTwoVentures: number;
  mentorsThreeVentures: number;
  mentorsApplicants: number;
  venturesActiveLastMonth: number;
  venturesActive: number;
  venturesOnboardedThisMonth: number;
  venturesNotAssigned: number;
  venturesOneMentor: number;
  venturesTwoMentors: number;
  venturesThreeMentors: number;
  venturesNoEvents: number;
  venturesApplicants: number;
  eventsLastMonth: number;
  eventsThisMonth: number;
  eventsNoAgenda: number;
  eventsRequireAttention: number;
}

export interface DashboardReportResult {
  mentors: {
    active: number;
    increased: number;
    decreased: number;
    notAssigned: number;
    oneVenture: number;
    twoVentures: number;
    threeVentures: number;
    applicants: number;
  };
  ventures: {
    active: number;
    increased: number;
    decreased: number;
    notAssigned: number;
    oneMentor: number;
    twoMentors: number;
    threeMentors: number;
    noEvents: number;
    applicants: number;
  };
  events: {
    active: number;
    increased: number;
    decreased: number;
    notAssigned: number;
    noAgenda: number;
    needAttention: number;
  };
}

export interface VentureMonthlyReportItem {
  ventureName: string;
  ventureDescription: string;
  founderList: string[];
  mentorList: string[];
  ventureNoteList: VentureNote;
}

export interface VentureReportItem {
  ventureName: string;
  ventureDescription: string;
  founderList: string[];
  mentorList: string[];
  ventureNoteList: VentureNote[];
  founderProgressRating: number;
  founderSessionRating: number;
  mentorProgressRating: number;
  mentorSessionRating: number;
}

export interface VentureFullReport {
  totalNumberOfSessions: number;
  totalNumberOfReports: number;
  venturesWithNoReports: string[];
  ventureReportItems: VentureReportItem[];
}

export interface GeneralReport {
  id: string;
  tenantId: string;
  creationDate: string;
  mentorsActive: number;
  mentorsJoined: number;
  mentorsApplicants: number;
  mentorsLeft: number;
  mentorsMale: number;
  mentorsFemale: number;
  mentorsWhite: number;
  mentorsNonWhite: number;
  mentorsWhiteMale: number;
  foundersActive: number;
  foundersJoined: number;
  foundersApplicants: number;
  foundersLeft: number;
  foundersMale: number;
  foundersFemale: number;
  foundersWhite: number;
  foundersNonWhite: number;
  foundersWhiteMale: number;
  venturesActive: number;
  venturesJoined: number;
  venturesApplicants: number;
  venturesLeft: number;
  meetingQualityFounders: number;
  meetingQualityMentors: number;
  ventureProgressFounder: number;
  ventureProgressMentor: number;
  numberOfSessions: number;
  eventEnjoymentRatingFounder: number;
  eventEnjoymentRatingMentor: number;
  mentorTeamImpactRatingFounder: number;
  mentorTeamImpactRatingMentor: number;
  ventureReadinessRatingFounder: number;
  ventureReadinessRatingMentor: number;
}

export interface MentorAttendanceReport {
  ok: string;
  bad: string;
  great: string;
}

export interface MentorAttendanceReportNumber {
  ok: number;
  bad: number;
  great: number;
}

export interface MentorMonthlyReportItem {
  mentor: Mentor;
  attendance: number;
}

export interface VentureProgress {
  ventureId: VentureId;
  ventureName: string;
  businessGoals: number;
  founderGoals: number;
}

export interface DashboardMentorsReport {
  newApplications: number;
  activelyRecruiting: number;
  votedFor: number;
  ventureProgress: VentureProgress[];
  numberOfSessionsAttended: number;
  leadMentorReportsSubmitted: number;
  mentorAssessmentsSubmitted: number;
  mentorLevel: number;
  mentorScore: number;
  mentorXp: number;
}

const reportsAPI = {
  async dashboardReport(): Promise<DashboardReportResult> {
    try {
      const res = await axiosRequest.get<DashboardReportResponse>({
        url: '/reports/dashboards/main',
        credentials: true,
      });
      const {
        data: {
          mentorsActiveLastMonth,
          mentorsActive,
          mentorsOnboardedThisMonth,
          mentorsNotAssigned,
          mentorsOneVenture,
          mentorsTwoVentures,
          mentorsThreeVentures,
          mentorsApplicants,
          venturesActiveLastMonth,
          venturesActive,
          venturesOnboardedThisMonth,
          venturesNotAssigned,
          venturesOneMentor,
          venturesTwoMentors,
          venturesThreeMentors,
          venturesNoEvents,
          venturesApplicants,
          eventsLastMonth,
          eventsThisMonth,
          eventsNoAgenda,
          eventsRequireAttention,
        },
      } = res;
      const mentorsLeft = mentorsActiveLastMonth - mentorsActive;
      const venturesLeft = venturesActiveLastMonth - venturesActive;
      const eventsCountDifference = eventsThisMonth - eventsLastMonth;
      return {
        mentors: {
          active: mentorsActive,
          increased: mentorsOnboardedThisMonth,
          decreased: mentorsLeft > 0 ? mentorsLeft : 0,
          notAssigned: mentorsNotAssigned,
          oneVenture: mentorsOneVenture,
          twoVentures: mentorsTwoVentures,
          threeVentures: mentorsThreeVentures,
          applicants: mentorsApplicants,
        },
        ventures: {
          active: venturesActive,
          increased: venturesOnboardedThisMonth,
          decreased: venturesLeft > 0 ? venturesLeft : 0,
          notAssigned: venturesNotAssigned,
          oneMentor: venturesOneMentor,
          twoMentors: venturesTwoMentors,
          threeMentors: venturesThreeMentors,
          noEvents: venturesNoEvents,
          applicants: venturesApplicants,
        },
        events: {
          active: eventsThisMonth,
          increased:
            eventsCountDifference > 0 ? Math.abs(eventsCountDifference) : 0,
          decreased:
            eventsCountDifference < 0 ? Math.abs(eventsCountDifference) : 0,
          notAssigned: 0,
          noAgenda: eventsNoAgenda,
          needAttention: eventsRequireAttention,
        },
      };
    } catch (e: any) {
      return {
        mentors: {
          active: 0,
          increased: 0,
          decreased: 0,
          notAssigned: 0,
          oneVenture: 0,
          twoVentures: 0,
          threeVentures: 0,
          applicants: 0,
        },
        ventures: {
          active: 0,
          increased: 0,
          decreased: 0,
          notAssigned: 0,
          oneMentor: 0,
          twoMentors: 0,
          threeMentors: 0,
          noEvents: 0,
          applicants: 0,
        },
        events: {
          active: 0,
          increased: 0,
          decreased: 0,
          notAssigned: 3,
          noAgenda: 0,
          needAttention: 24,
        },
      };
    }
  },
  async getVentureReportItem(startDate: string, endDate: string) {
    try {
      const res = await axiosRequest.get<VentureFullReport>({
        url: `/reports/ventures?startDate=${startDate}&endDate=${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return undefined;
      }
      throw e;
    }
  },
  async getMentorReportAttendance(startDate: string, endDate: string) {
    try {
      const res = await axiosRequest.get<MentorAttendanceReport>({
        url: `/reports/mentors/attendance?startDate=${startDate}&endDate=${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return {
          bad: '0',
          ok: '0',
          great: '0',
        };
      }
      throw e;
    }
  },
  async getMentorReportAttendanceGreat(startDate: string, endDate: string) {
    try {
      const res = await axiosRequest.get<MentorMonthlyReportItem[]>({
        url: `/reports/mentors/attendance/great?startDate=${startDate}&endDate=${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getMentorReportAttendanceOk(startDate: string, endDate: string) {
    try {
      const res = await axiosRequest.get<MentorMonthlyReportItem[]>({
        url: `/reports/mentors/attendance/ok?startDate=${startDate}&endDate=${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getMentorReportAttendanceBad(startDate: string, endDate: string) {
    try {
      const res = await axiosRequest.get<MentorMonthlyReportItem[]>({
        url: `/reports/mentors/attendance/bad?startDate=${startDate}&endDate=${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getMentorAdditionalReportAttendance(
    startDate: string,
    endDate: string,
  ) {
    try {
      const res = await axiosRequest.get<MentorAttendanceReport>({
        url: `/reports/mentors/attendance/other?startDate=${startDate}&endDate=${endDate}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return {
          bad: '0',
          ok: '0',
          great: '0',
        };
      }
      throw e;
    }
  },
  async getGeneralReportItem(date?: string) {
    const url = date
      ? `/reports/monthly/demographics/bydate?date=${formatDateApi(
          new Date(date),
        )}`
      : '/reports/monthly/demographics';
    try {
      const res = await axiosRequest.get<GeneralReport>({
        url,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return undefined;
      }
      throw e;
    }
  },
  async getGeneralReportDates() {
    try {
      const res = await axiosRequest.get<string[]>({
        url: '/reports/monthly/demographics/dates',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getGeneralReportYear(year: string) {
    try {
      const res = await axiosRequest.get<GeneralReport[]>({
        url: `/reports/monthly/demographics/year?year=${year}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getGeneralReportMentors(
    reportId: string,
    status: 'new' | 'applied' | 'archived',
  ) {
    try {
      const res = await axiosRequest.get<Mentor[]>({
        url: `/reports/monthly/demographics/mentors/${status}?recordId=${reportId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getGeneralReportVentures(
    reportId: string,
    status: 'new' | 'applied' | 'archived',
  ) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/reports/monthly/demographics/ventures/${status}?recordId=${reportId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getQuarterlyReportDates() {
    try {
      const res = await axiosRequest.get<QuarterlyReportDate[]>({
        url: '/reports/quarterly/ventures/dates',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getQuarterlyReportItem(date?: { quarter: number; year: number }) {
    const url = date
      ? `/reports/quarterly/ventures/dates/years/${date.year}/quarters/${date.quarter}`
      : '/reports/quarterly/ventures';
    try {
      const res = await axiosRequest.get<QuarterlyReportRecord>({
        url,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return undefined;
      }
      throw e;
    }
  },
  async getQuarterlyReportYear(year: number) {
    try {
      const res = await axiosRequest.get<QuarterlyReportRecord[]>({
        url: `/reports/quarterly/ventures/dates/years/${year}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getDashboardMentors(): Promise<DashboardMentorsReport> {
    try {
      const res = await axiosRequest.get<DashboardMentorsReport>({
        url: '/reports/dashboards/mentors',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getDashboardFounders(): Promise<DashboardFoundersReport> {
    try {
      const res = await axiosRequest.get<DashboardFoundersReport>({
        url: '/reports/dashboards/founders',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
};

export default reportsAPI;
