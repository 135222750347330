import { CircularProgress, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Settings } from '../../api/tenants/types/Settings';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { formatDateToRFC } from '../../utils/date';
import { lengthField } from '../../utils/form';
import { validateEmail } from '../../utils/functions';
import { AttachFilesButton, Button, FormButtons, FormGroup } from '../common';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile,
} from '../common/attachment-card';
import {
  FormDateTimeInput,
  FormRating,
  FormSelect,
  TextFieldSummary,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from '../forms/wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  formContainer: {
    width: '100%',
  },
  largeTextFormGroup: {
    maxWidth: '100%',
  },
  ventureUpdate: {
    '& .MuiInputBase-inputMultiline': {
      minHeight: 64,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
  },

  formButtons: {
    justifyContent: 'flex-start',
  },
  fileName: {
    maxWidth: '200px !important',
  },
}));

export interface Params {
  label: string;
  value: string;
}

export interface FormValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  beforeNextSession: string;
  other: string;
  attachments: string;
  mostImpactfulMentor: string;
  sessionRating?: number;
  ventureReadinessRating?: number;
  mentorTeamImpactRating?: number;
  eventEnjoymentRating?: number;
}

export interface FormInitialValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  beforeNextSession: string;
  other: string;
  attachments: Attachment[];
  mostImpactfulMentor: string;
  sessionRating?: number;
  ventureReadinessRating?: number;
  mentorTeamImpactRating?: number;
  eventEnjoymentRating?: number;
}

interface Props {
  onSubmit: (values: FormValues) => void;
  onUploadFile: (file: File) => Promise<string | undefined>;
  loading: boolean;
  initialValues?: FormInitialValues;
  errorVaildDate?: boolean;
  optionsMentor: Params[];
  formSettings: Partial<Settings>;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const getValidateForm =
  (formSettings: Partial<Settings>) => (values: FormValues) => {
    const errors: Errors = {};

    if (!values.submitterEmail) {
      errors.submitterEmail = 'Required';
    } else if (!validateEmail(values.submitterEmail)) {
      errors.submitterEmail = 'Invalid';
    }
    if (
      !values.sessionRating &&
      formSettings?.founderNotesSessionRatingQuestionEnabled
    ) {
      errors.sessionRating = 'Required';
    }
    if (
      !values.ventureReadinessRating &&
      formSettings?.founderNotesVentureReadinessQuestionEnabled
    ) {
      errors.ventureReadinessRating = 'Required';
    }
    if (
      !values.mentorTeamImpactRating &&
      formSettings?.founderNotesMentorTeamImpactQuestionEnabled
    ) {
      errors.mentorTeamImpactRating = 'Required';
    }
    if (
      !values.eventEnjoymentRating &&
      formSettings?.founderNotesSessionEnjoymentQuestionEnabled
    ) {
      errors.eventEnjoymentRating = 'Required';
    }
    if (!values.beforeNextSession) {
      errors.beforeNextSession = 'Required';
    }

    return errors;
  };

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

function ReportFounderSessionNotesForm({
  onSubmit,
  loading,
  initialValues,
  onUploadFile,
  optionsMentor,
  formSettings,
}: Props) {
  const classes = useStyles();
  const isSubmitDisabled = loading;
  const { rb } = useResourceBundles();
  const setSubmitValidationFailed = useScrollOnValidation();

  const [attachments, setAttachments] = useState<Attachment[]>(
    initialValues?.attachments || [],
  );

  const handleSubmit = (values: FormValues) => {
    const attachmentRefs = formatAttachments(attachments);
    onSubmit({ ...values, attachments: attachmentRefs });
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  const handleFileUpload = async (file: File) => {
    try {
      const fileIndex = attachments.length;
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        getAttachmentFromFile(file),
      ]);
      const fileURL = await onUploadFile(file);
      setAttachments((prevAttachments) => {
        return prevAttachments.map((prevAttach, prevAttachIndex) => {
          if (prevAttachIndex !== fileIndex) {
            return prevAttach;
          }
          return {
            ...prevAttach,
            url: fileURL,
          };
        });
      });
    } catch (e: any) {}
  };

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  return (
    <Form
      validate={getValidateForm(formSettings)}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={(formProps) => {
        setSubmitValidationFailed(
          formProps.submitFailed &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting,
        );
        return (
          <div className={classes.formBlocks}>
            <form noValidate className={classes.formContainer}>
              <FormGroup>
                <Field<string>
                  name='summary'
                  component={TextFieldSummary}
                  label='Summary'
                  disabled
                  testid='report-founder-notes-form-summary'
                />
              </FormGroup>
              <FormGroup mobile>
                <Field<string>
                  name='start'
                  component={FormDateTimeInput}
                  label='Start'
                  parse={(value) => formatDateToRFC(value)}
                  disabled
                  testId='report-founder-notes-form-start'
                />
                <Field<string>
                  name='end'
                  component={FormDateTimeInput}
                  label='End'
                  parse={(value) => formatDateToRFC(value)}
                  disabled
                  testId='report-founder-notes-form-end'
                />
              </FormGroup>
              <FormGroup>
                <Field<string>
                  name='submitterEmail'
                  component={TextFieldWrapper}
                  label='Email*'
                  testid='report-founder-notes-form-email'
                  InputProps={{
                    inputProps: {
                      maxLength: lengthField.email,
                    },
                  }}
                  formatOnBlur
                  format={(value: string) => {
                    return value ? value.toLowerCase() : value;
                  }}
                  disabled={!!initialValues?.submitterEmail}
                />
              </FormGroup>
              {formSettings?.founderNotesSessionRatingQuestionEnabled && (
                <FormGroup>
                  <Field<number>
                    name='sessionRating'
                    component={FormRating}
                    testid='report-founder-notes-form-session-rating'
                    label='How would you rate this session? *'
                  />
                </FormGroup>
              )}
              {formSettings?.founderNotesVentureReadinessQuestionEnabled && (
                <FormGroup>
                  <Field<number>
                    name='ventureReadinessRating'
                    component={FormRating}
                    label='How well prepared was your venture for the meeting - did all the homework, submitted agenda, etc.? *'
                    testid='report-lead-mentor-form-venture-readiness-rating'
                  />
                </FormGroup>
              )}

              {formSettings?.founderNotesMentorTeamImpactQuestionEnabled && (
                <FormGroup>
                  <Field<number>
                    name='mentorTeamImpactRating'
                    component={FormRating}
                    label='How do you rate the impact of the mentor team as a whole in this meeting? *'
                    testid='report-lead-mentor-form-mentor-team-impact-rating'
                  />
                </FormGroup>
              )}
              {formSettings?.founderNotesSessionEnjoymentQuestionEnabled && (
                <FormGroup>
                  <Field<number>
                    name='eventEnjoymentRating'
                    component={FormRating}
                    label='How enjoyable was this mentor meeting for the venture team as a whole? *'
                    testid='report-lead-mentor-form-event-enjoyment-rating'
                  />
                </FormGroup>
              )}

              <FormGroup className={classes.largeTextFormGroup}>
                <Field<string>
                  name='beforeNextSession'
                  testid='report-founder-notes-form-next-session'
                  label={rb('what-are-the-agreed-upon-things')}
                  placeholder='Type your answer here...'
                  component={TextFieldWysiwyg}
                  className={classes.ventureUpdate}
                  InputProps={{
                    inputProps: {
                      maxLength: lengthField.ventureUpdate,
                    },
                  }}
                />
              </FormGroup>

              <FormGroup className={classes.largeTextFormGroup}>
                <Field<string>
                  name='other'
                  testid='report-founder-notes-form-other'
                  label={rb('other-notes-takeaways')}
                  placeholder='Type here...'
                  component={TextFieldWysiwyg}
                  className={classes.ventureUpdate}
                  multiline
                  InputProps={{
                    inputProps: {
                      maxLength: lengthField.ventureUpdate,
                    },
                  }}
                />
              </FormGroup>

              {formSettings.mostImpactfulMentorEnabled && (
                <FormGroup>
                  <Field<string>
                    name='mostImpactfulMentor'
                    testid='founder-session-high-value-mentor-id'
                    component={FormSelect}
                    label={`Who was the most impactful ${rb(
                      'mentor',
                    )} during the session?`}
                    options={optionsMentor}
                  />
                </FormGroup>
              )}

              <div className={classes.attachBtn}>
                <AttachFilesButton onChange={handleSelectFile} />
              </div>
              <div className={classes.attachmentsList}>
                {attachments.map((attachment, attachIndex) => (
                  <div key={attachIndex} className={classes.attachment}>
                    <AttachmentCard
                      attachment={attachment}
                      loading={!attachment.url}
                      onRemove={() => handleFileRemove(attachIndex)}
                      classesFileName={classes.fileName}
                    />
                  </div>
                ))}
              </div>

              <FormButtons className={classes.formButtons}>
                <Button
                  onClick={formProps.handleSubmit}
                  data-testid='button-submit-form'
                  disabled={isSubmitDisabled}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save'
                  )}
                </Button>
              </FormButtons>
            </form>
          </div>
        );
      }}
    />
  );
}

export default ReportFounderSessionNotesForm;
