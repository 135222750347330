import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Field, Form } from 'react-final-form';
import { VenturesAdditionalInfo } from '../../api/ventures/types/VenturesAdditionalInfo';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import {
  DEDICATION,
  DONE_ALREADY,
  GROWTH_RATIO,
  lengthField,
  MARKET_SIZE,
  REASON_TO_START_NOW,
  SUPPORT_INTERESTED_RECEIVING,
} from '../../utils/form';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  CheckboxListOther,
  FormGroup,
  StickyContent,
} from '../common';
import { FormCheckbox, FormSelect, TextFieldWysiwyg } from './wrappers';

interface Props {
  initialValues: object;
  isLoading: boolean;
  onSubmit: (value: FormAdditionalInfo) => void;
  hasAccessToUpdate?: boolean;
}

export type FormAdditionalInfo = Pick<
  VenturesAdditionalInfo,
  | 'howDidYouFind'
  | 'isLegallyRegistered'
  | 'businessDescription'
  | 'currentProgress'
  | 'requiredSupport'
  | 'marketSize'
  | 'reasonToStartNow'
  | 'plans'
  | 'growthRatio'
  | 'dedication'
  | 'yearPlans'
  | 'obstacles'
  | 'mentorshipPlans'
  | 'comments'
>;
type Errors = {
  [K in keyof FormAdditionalInfo]?: string;
};

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '65px 0 0 80px',
  },
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up(900)]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  checkbox: {
    paddingLeft: 0,
  },
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}));

const validateForm = (values: FormAdditionalInfo) => {
  const errors: Errors = {};
  return errors;
};

function VentureAdditionalInfoForm({
  initialValues,
  isLoading,
  onSubmit,
  hasAccessToUpdate = false,
}: Props) {
  const loading = isLoading;
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const handleSubmit = (value: FormAdditionalInfo) => {
    onSubmit(value);
  };

  const handleChangeField = (onChange: any, name: string, value: string) => {
    onChange(name, value);
  };

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={(formProps) => (
          <div>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={loading || !formProps.valid}>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div className={classes.mainFormBlock}>
              <form noValidate>
                <div className={classes.sectionBlock}>
                  <FormGroup>
                    <Field<string>
                      name='howDidYouFind'
                      testid='venture-additional-find'
                      component={TextFieldWysiwyg}
                      label='How did you learn about our program?'
                      placeholder='Type your answer here...'
                      readOnly={!hasAccessToUpdate}
                      maxLength={lengthField.additionalInfo}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='isLegallyRegistered'
                      testid='venture-additional-legally-registered'
                      component={FormCheckbox}
                      label='Are you a legally registered business?'
                      className={classes.checkbox}
                      classNameLabel={classes.checkboxLabel}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='businessDescription'
                      testid='venture-additional-business-description'
                      component={TextFieldWysiwyg}
                      label='Please describe what your business does'
                      placeholder='Type your answer here...'
                      maxLength={lengthField.additionalInfo}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <CheckboxListOther
                    name='currentProgress'
                    testid='venture-additional-current-progress'
                    options={DONE_ALREADY}
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'currentProgress',
                        value,
                      )
                    }
                    text='What have you done already? (select all that apply)'
                    labelOther='Other option what have you already done'
                    value={formProps.values.currentProgress ?? undefined}
                    readOnly={!hasAccessToUpdate}
                  />

                  <CheckboxListOther
                    name='requiredSupport'
                    testid='venture-additional-required-support'
                    options={SUPPORT_INTERESTED_RECEIVING}
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'requiredSupport',
                        value,
                      )
                    }
                    text={`What support are you interested in receiving? (select all that apply) Note that not all of those things are offered by Our ${rb(
                      'mentorship-u',
                    )} Program, but we'll link you up with the right resources`}
                    labelOther='Other option for necessary support'
                    value={formProps.values.requiredSupport ?? undefined}
                    readOnly={!hasAccessToUpdate}
                  />
                  <FormGroup>
                    <Field<string>
                      name='marketSize'
                      testid='venture-additional-market-size'
                      component={FormSelect}
                      label='How big is the opportunity you see in the market for your business?'
                      options={MARKET_SIZE}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='reasonToStartNow'
                      testid='venture-additional-reason-start-now'
                      component={TextFieldWysiwyg}
                      label='Why now is the time to pursue this opportunity?'
                      placeholder='Type your answer here...'
                      maxLength={lengthField.additionalInfo}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='plans'
                      testid='venture-additional-plans'
                      component={FormSelect}
                      label='What better describes your plans for this business?'
                      options={REASON_TO_START_NOW}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='growthRatio'
                      testid='venture-additional-growth-ratio'
                      component={FormSelect}
                      label='How quickly do you see your business growing?'
                      options={GROWTH_RATIO}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='dedication'
                      testid='venture-additional-dedication'
                      component={FormSelect}
                      label='Are you working on this business full-time?'
                      options={DEDICATION}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='yearPlans'
                      testid='venture-additional-year-plans'
                      component={TextFieldWysiwyg}
                      label='What are you hoping to accomplish in the next 12 months?'
                      placeholder='Type your answer here...'
                      maxLength={lengthField.additionalInfo}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='obstacles'
                      testid='venture-additional-obstacles'
                      component={TextFieldWysiwyg}
                      label='What obstacles do you foresee in reaching these goals?'
                      placeholder='Type your answer here...'
                      maxLength={lengthField.additionalInfo}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='mentorshipPlans'
                      testid='venture-additional-mentorship-plans'
                      component={TextFieldWysiwyg}
                      label={`How do you think ${rb(
                        'mentorship',
                      )} is going to help you achieve these?`}
                      placeholder='Type your answer here...'
                      maxLength={lengthField.additionalInfo}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field<string>
                      name='comments'
                      testid='venture-additional-comments'
                      component={TextFieldWysiwyg}
                      label='Comments'
                      placeholder='Type here...'
                      maxLength={lengthField.additionalInfo}
                      readOnly={!hasAccessToUpdate}
                    />
                  </FormGroup>
                </div>
                {hasAccessToUpdate && (
                  <div className={classes.actionsBlock}>
                    <StickyContent>
                      <Button
                        className={CLASS_TRACKING.INTERNAL_ACTION}
                        onClick={formProps.handleSubmit}
                        disabled={loading || !formProps.valid}
                        startIcon={<CheckIcon />}>
                        {loading ? (
                          <CircularProgress size={24} color='inherit' />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </StickyContent>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default VentureAdditionalInfoForm;
