import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import cn from 'classnames';
import { Text } from '.';
import { COLORS } from '../../theme/variables';

interface TogglerOption<T extends string | number | null> {
  label: string | React.ReactNode;
  value: T;
  className?: string;
  testId?: string;
}

interface TogglerProps<T extends string | number | null> {
  value: T;
  options?: TogglerOption<T>[];
  className?: string;
  onChange: (value: T) => any;
  withIcon?: boolean;
  disabled?: boolean;
  mini?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'stretch',
  },
  icon: {
    marginRight: 8,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    background: 'white',
    cursor: 'pointer',
    padding: '7px 20px',
    outline: 'none',
    marginRight: -1,
    justifyContent: 'center',

    '&:not($active)': {
      '&:hover': {
        background: COLORS.COLOR_GRAY_LIGHTENED_50,
      },

      '&:active': {
        background: COLORS.COLOR_GRAY_LIGHTENED_40,
      },
    },

    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },

    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  text: {
    fontWeight: 500,
  },
  active: {
    position: 'relative',
    background: COLORS.COLOR_GREEN_LIGHTENED_45,
    borderColor: COLORS.COLOR_GREEN_LIGHTENED_10,
    color: COLORS.COLOR_GREEN_BASE,
    zIndex: 2,
  },
  mini: {
    padding: '2px 12px',
  },
});

function Toggler<T extends string | number | null>({
  value,
  options = [],
  onChange,
  className,
  withIcon = true,
  disabled,
  mini,
}: TogglerProps<T>) {
  const classes = useStyles();

  const handleOptionChange = (option: TogglerOption<T>) => () => {
    onChange(option.value);
  };

  const getDataTestid = (
    isActive: boolean,
    option?: TogglerOption<T>,
  ): string => {
    const stringActive = isActive ? '-active' : '';
    if (typeof option?.label === 'string') {
      return `${option.label
        .toLocaleLowerCase()
        .replaceAll(' ', '-')}-toggler${stringActive}`;
    }
    if (typeof option?.value === 'string') {
      return `${option.value
        .toLocaleLowerCase()
        .replaceAll(' ', '-')}-toggler${stringActive}`;
    }
    return '';
  };

  return (
    <div className={cn(classes.container, className)}>
      {options.length > 0 &&
        options.map((option) => {
          const isActive = value === option.value;

          return (
            <button
              key={option.value}
              disabled={disabled}
              data-testid={option.testId ?? getDataTestid(isActive, option)}
              onClick={handleOptionChange(option)}
              className={cn(classes.button, option?.className, {
                [classes.active]: isActive,
                [classes.mini]: mini,
              })}>
              {isActive && withIcon && (
                <CheckIcon className={classes.icon} color='inherit' />
              )}
              {typeof option.label === 'string' ? (
                <Text className={classes.text} variant='normal' inherit>
                  {option.label}
                </Text>
              ) : (
                option.label
              )}
            </button>
          );
        })}
    </div>
  );
}

export default Toggler;
