import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import eventsAPI, {
  EventFullDetails,
  EventResponseForm,
} from '../../api/events';
import { getSettings } from '../../api/tenants';
import venturesAPI from '../../api/ventures';
import { SnackMessage, Text } from '../../components/common';
import WarningBeforeStart from '../../components/common/Confirmation/WarningBeforeStart';
import ReportMentorAssessmentForm, {
  FormInitialValues,
  FormValues,
} from '../../components/forms/report-mentor-assessment-form';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { getTenantId, parseKey } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 15,
  },
}));

const formatValuesRequest = (
  values: FormValues,
  fullDetails: EventFullDetails,
): EventResponseForm => {
  return {
    tenantId: fullDetails.event.tenantId,
    eventId: fullDetails.event.id,
    ventureId: fullDetails.event.ventureId,
    sessionRating: +values.sessionRating,
    ventureProgressRating: +values.ventureProgressRating,
    attendingMentors: [],
    attendingFounders: [],
    mostImpactfulMentor: values?.mostImpactfulMentor || '',
    submitterEmail: values.submitterEmail,
    mentorReport: values.notes || '',
    mentorReportAttachments: values.attachments || '',
    mentorReportNotes: '',
    founderNotes: '',
    founderOtherNotes: '',
    founderNotesAttachments: '',
    agendaQuestion1: '',
    agendaQuestion2: '',
    agendaQuestion3: '',
    agendaQuestion4: '',
    agendaAttachments: '',
    isLeadMentorReport: false,
    nextEventStart: '',
    nextEventEnd: '',
    isMentorReport: true,
    isFounderReport: false,
    founderReport: false,
    agendaPresented: false,
  };
};

const getInitialValues = (fullDetails: EventFullDetails): FormInitialValues => {
  return {
    submitterEmail: '',
    summary: fullDetails?.event.summary || '',
    start: fullDetails?.event.start || '',
    end: fullDetails?.event.end || '',
    sessionRating: 0,
    ventureProgressRating: 0,
    notes: '',
    attachments: [],
    mostImpactfulMentor: '',
  };
};

interface Payload extends EventFullDetails {
  leadingMentor: boolean;
}

function ReportPage() {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();

  const [fullDetails, setFullDetails] = useState<Payload>();
  const [statusForm, setStatusForm] = useState<
    'success' | 'loading' | 'error' | 'no-mentors' | ''
  >('loading');
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const [errorVaildDate, setErrorVaildDate] = useState(false);

  const key = useMemo(() => parseKey(location.search), [location.search]);

  const initialValues = useMemo(() => {
    if (fullDetails) {
      return getInitialValues(fullDetails);
    }
    return undefined;
  }, [fullDetails]);

  const optionsMentor = useMemo(() => {
    return fullDetails?.mentorList && fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => {
          return {
            value: mentor.id,
            label: `${mentor.firstName} ${mentor.lastName}`,
          };
        })
      : [];
  }, [fullDetails?.mentorList]);

  const handleSubmit = async (values: FormValues) => {
    const valuesRequest = formatValuesRequest(
      values,
      fullDetails as EventFullDetails,
    );

    try {
      setIsLoadingSaveForm(true);

      await eventsAPI.eventsResponseForm(valuesRequest);
      setErrorVaildDate(false);
      setStatusForm('success');
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';
      enqueueSnackbar('An error occurred while saving your report', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        if (fullDetails?.event.ventureId) {
          const loadedFileURL = await venturesAPI.attachFileToReport(
            fullDetails.event.ventureId,
            fullDetails.event.tenantId,
            file,
          );
          return loadedFileURL;
        }
      } catch (e: any) {
        return '';
      }
    },
    [fullDetails],
  );

  const loadDetails = async (key: string) => {
    const tenantId = getTenantId(key);

    if (!tenantId) {
      setError('Invalid key value');
      setStatusForm('error');
      return;
    }

    try {
      setStatusForm('loading');
      const responseDetails = await eventsAPI.getEventDetails(key);
      const { mostImpactfulMentorEnabled } = await getSettings(tenantId);
      setFullDetails({
        ...responseDetails,
        leadingMentor: mostImpactfulMentorEnabled,
      });

      if (!responseDetails.mentorList?.length) {
        setStatusForm('no-mentors');
      } else {
        setStatusForm('');
      }
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setStatusForm('error');
    }
  };

  useEffect(() => {
    if (key) {
      loadDetails(key);
    } else {
      setError('Invalid key value');
      setStatusForm('error');
    }
  }, [key]);

  useEffect(() => {
    document.body.style.minWidth = 'auto';
  }, []);

  if (statusForm === 'loading') {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === 'success') {
    return (
      <div
        data-testid='report-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>Success!</Text>
          </div>
        </div>
      </div>
    );
  }

  if (statusForm === 'no-mentors') {
    return (
      <div
        data-testid='report-page-no-mentors'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              You cannot submit {rb('mentors-u')} Assessment & Notes for this
              session since no {rb('mentors-u')} are assigned.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  if (statusForm === 'error' || !fullDetails) {
    return (
      <div
        data-testid='report-page-error'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Unfortunately an error occurred while loading the report. Please
              try again in a couple of minutes. If the problem persists please
              share the URL with the support team at support@tractionfive.com
              <br />
              Details: {error}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <WarningBeforeStart date={fullDetails.event.start} />
      <div data-testid='report-page' className={classes.container}>
        <div className={classes.block}>
          <Typography variant='h2' className={classes.title}>
            {rb('mentor-u')} Assessment & Notes
          </Typography>
          <ReportMentorAssessmentForm
            initialValues={initialValues}
            fullDetails={fullDetails}
            onSubmit={handleSubmit}
            loading={isLoadingSaveForm}
            errorVaildDate={errorVaildDate}
            onUploadFile={handleUploadFile}
            optionsMentor={optionsMentor}
            leadingMentor={fullDetails.leadingMentor}
          />
        </div>
      </div>
    </>
  );
}

export default ReportPage;
