import { makeStyles, Tooltip, IconButton, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Settings from '@material-ui/icons/Settings';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CopyIcon } from '../../assets/icons';
import { Text } from '../../components/common';
import TenantAccordion from '../../pages/tenant/tenant-accordion';
import { Pages, getRoutePath } from '../../router/constants';
import { COLORS } from '../../theme/variables';

interface FormSectionProps {
  id: string;
  label: string;
  link: string;
  description: string;
  copied: string | null;
  onCopy: (text: string, id: string) => void;
  expanded?: boolean;
  onExpand?: () => void;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
    cursor: 'pointer',
    marginLeft: 8,

    '&:hover': {
      color: COLORS.COLOR_BLUE_BASE,
    },
  },
  successIcon: {
    fontSize: 16,
    color: COLORS.COLOR_GREEN_BASE,

    '&:hover': {
      color: COLORS.COLOR_GREEN_BASE,
    },
  },
  summaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

function FormSection({
  id,
  label,
  link,
  description,
  copied,
  onCopy,
  expanded,
  onExpand,
}: FormSectionProps) {
  const classes = useStyles();

  return (
    <TenantAccordion
      expanded={expanded}
      onExpand={onExpand}
      summary={
        <div className={classes.summaryContainer}>
          <Typography variant='h5'>{label}</Typography>
          {copied !== id ? (
            <Tooltip title='Copy link'>
              <span
                data-link={link}
                data-testid={`button-link-copy-${id}`}
                onClick={() => onCopy(link, id)}>
                <CopyIcon className={classes.icon} />
              </span>
            </Tooltip>
          ) : (
            <CheckCircleIcon
              className={cn(classes.icon, classes.successIcon)}
            />
          )}
        </div>
      }
      actions={
        <Tooltip title={`${label} Settings`}>
          <IconButton
            component={Link}
            to={getRoutePath(Pages.TENANT_FORMS_EDIT, {
              formId: id,
            })}
            data-testid={`${id}-application-form-settings`}>
            <Settings />
          </IconButton>
        </Tooltip>
      }>
      <Text variant='normal'>{description}</Text>
    </TenantAccordion>
  );
}

export default FormSection;
