import { makeStyles, Typography, Switch, FormLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext } from 'react';
import authAPI, { Tenant } from '../../api/auth';
import { SnackMessage } from '../../components/common';
import { InfoTooltip } from '../../components/common/InfoTooltip';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { COLORS } from '../../theme/variables';

type TenantKeyType = keyof Tenant;

interface ToggleProps {
  name: TenantKeyType;
  value: boolean;
  label: string;
  moduleName: string;
  handleChange: (
    key: TenantKeyType,
    value: boolean,
    moduleName: string,
  ) => void;
  disabled?: boolean;
  tooltip?: string;
}

const useStyles = makeStyles({
  container: {
    maxWidth: 420,
  },
  title: {
    margin: '0 0 20px',
  },
  toggleList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  label: {
    color: COLORS.COLOR_TEXT_BASE,
  },
});

const Toggle: FC<ToggleProps> = ({
  name,
  value,
  label,
  moduleName,
  disabled,
  tooltip,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toggleWrapper}>
      <div className={classes.toggleContainer}>
        <FormLabel htmlFor={name} className={classes.label}>
          {label}
        </FormLabel>
        {tooltip && <InfoTooltip message={tooltip} />}
      </div>
      <Switch
        checked={value}
        color='primary'
        onChange={(_, checked) => handleChange(name, checked, moduleName)}
        disabled={disabled}
        id={name}
        name={name}
      />
    </div>
  );
};

function TenantFormsFounderNotesReportPage() {
  const classes = useStyles();
  const { user, updateUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();

  const handleChange = useCallback(
    async (key: TenantKeyType, value: boolean, moduleName: string) => {
      try {
        if (user) {
          const updatedTenant = await authAPI.updateTenant(user?.id, {
            ...user,
            [key]: value,
            timeZone: user.timeZone ?? 'US/Eastern',
          });
          updateUser(updatedTenant);
          const message = value
            ? `${moduleName} is enabled`
            : `${moduleName} is disabled`;
          enqueueSnackbar(message, {
            variant: 'success',
          });
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        const message = value
          ? `An error occurred while activating ${moduleName} module. Please, try again.`
          : `An error occurred while deactivating ${moduleName} module. Please, try again.`;

        enqueueSnackbar(message, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, updateUser, user],
  );

  if (!user) return null;

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant='h4'>
        Founder Notes Report Form
      </Typography>
      <div className={classes.toggleList}>
        <Toggle
          name='founderNotesSessionRatingQuestionEnabled'
          value={!!user.founderNotesSessionRatingQuestionEnabled}
          label={'Overall Session Rating enabled'}
          moduleName={'Overall Session Rating'}
          handleChange={handleChange}
        />
        <Toggle
          name='founderNotesVentureReadinessQuestionEnabled'
          value={!!user.founderNotesVentureReadinessQuestionEnabled}
          label={'Venture Readiness Rating enabled'}
          moduleName={'Venture Readiness Rating'}
          handleChange={handleChange}
        />
        <Toggle
          name='founderNotesMentorTeamImpactQuestionEnabled'
          value={!!user.founderNotesMentorTeamImpactQuestionEnabled}
          label={`${rb('mentor-u')} Team Impact Rating enabled`}
          moduleName={`${rb('mentor-u')} Team Impact Rating`}
          handleChange={handleChange}
        />
        <Toggle
          name='founderNotesSessionEnjoymentQuestionEnabled'
          value={!!user.founderNotesSessionEnjoymentQuestionEnabled}
          label={'Session Enjoyment Rating enabled'}
          moduleName={'Session Enjoyment Rating'}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
}

export default TenantFormsFounderNotesReportPage;
