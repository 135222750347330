import {
  CircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isEqual } from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import * as Advisors from '../../api/Advisors';
import { Appointment } from '../../api/Appointments/types/Appointment';
import { Status } from '../../api/Appointments/types/Status';
import { TenantRFCDate } from '../../api/types/TenantRFCDate';
import { CopyIcon } from '../../assets/icons';
import {
  BeforeUnload,
  Button,
  FieldDatesDetails,
  FormGroup,
  StickyContent,
} from '../../components/common';
import { SearchSelectOption } from '../../components/common/search-select';
import {
  FormSelect,
  SearchSelectWrapper,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from '../../components/forms/wrappers';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { COLORS } from '../../theme/variables';
import { checkSizeTablet } from '../../utils/functions';
import { removeFalsyValues } from '../../utils/object';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { yupValidate } from '../../utils/yup';
import BeforeSubmit from '../common/Confirmation/BeforeSubmit';

export interface AppointmentFormValues {
  topic: string;
  start: TenantRFCDate;
  end: TenantRFCDate;
  location: string | null;
  requestorFullName: string;
  requestorEmail: string;
  status: Status;
  advisor: SearchSelectOption;
  agenda: string;
}

interface AppointmentOverviewProps {
  handleSubmit: (values: AppointmentFormValues) => Promise<void>;
  initialData?: Partial<Appointment> | null;
  showLocation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  mainFormBlock: {
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 560,
    },
  },
  actionsBlock: {
    marginTop: 56,
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  columns: {
    display: 'grid',
    gap: '30px',
    gridTemplateColumns: '1fr 1fr',
  },
  group: {
    display: 'grid',
    gap: '30px',
    alignContent: 'start',
  },
  row: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr',
  },
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.COLOR_BLUE_BASE,
    },
  },
  successIcon: {
    fontSize: 16,
    color: COLORS.COLOR_GREEN_BASE,

    '&:hover': {
      color: COLORS.COLOR_GREEN_BASE,
    },
  },
}));

const getInitialData = (
  appointment?: Partial<Appointment> | null,
): Partial<AppointmentFormValues> => {
  const nonFiltredInitialData: Partial<AppointmentFormValues> = {
    topic: appointment?.topic || '',
    start: appointment?.start,
    end: appointment?.end,
    location: appointment?.roomUrl || '',
    requestorFullName: appointment?.requestorFullName || '',
    requestorEmail: appointment?.requestorEmail || '',
    status: appointment?.status || Status.Requested,
    advisor: appointment?.advisorId
      ? {
          label: appointment?.advisorFullName || '',
          value: appointment.advisorId,
        }
      : {
          label: '',
          value: '',
        },
    agenda: appointment?.agenda || '',
  };

  return removeFalsyValues(nonFiltredInitialData);
};

const statusOptions: { label: string; value: Status }[] = [
  { label: 'Requested', value: Status.Requested },
  { label: 'Accepted', value: Status.Accepted },
  { label: 'Rejected', value: Status.Rejected },
  { label: 'Archived', value: Status.Archived },
  { label: 'Completed', value: Status.Completed },
  { label: 'Cancelled', value: Status.Cancelled },
];

function CopyButton({ text }: { text: string }) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    timeout.current = setTimeout(() => {
      setCopied(false);
    }, 4000);
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <Tooltip title='Copy link'>
      <IconButton onClick={handleCopy}>
        {copied ? (
          <CheckCircleIcon className={cn(classes.icon, classes.successIcon)} />
        ) : (
          <CopyIcon className={classes.icon} />
        )}
      </IconButton>
    </Tooltip>
  );
}

function AppointmentForm({
  initialData,
  handleSubmit,
  showLocation,
}: AppointmentOverviewProps) {
  const { user, hasAccessToAction } = useContext(UserContext);
  const classes = useStyles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const { rb } = useResourceBundles();

  const [openBeforeSubmit, setOpenBeforeSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        topic: yup.string().max(1024).required(),
        start: yup.date().required(),
        end: yup.date().min(yup.ref('start')).required(),
        requestorFullName: yup.string().max(512).required(),
        requestorEmail: yup.string().email().required(),
        status: yup.string().required(),
        advisor: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .required(),
        agenda: yup.string().max(4096).required(),
      }),
    [],
  );

  const onSubmit = useCallback(
    async (values: AppointmentFormValues) => {
      try {
        setIsSubmitting(true);
        await handleSubmit(values);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
        setOpenBeforeSubmit(false);
      }
    },
    [handleSubmit],
  );

  const preSubmit = useCallback(
    async (values: AppointmentFormValues) => {
      if (!openBeforeSubmit) {
        const tenantTime = new Date(values.start);
        const now = user?.timeZone
          ? utcToZonedTime(new Date(), user.timeZone)
          : new Date();

        if (isBefore(tenantTime, now)) {
          setOpenBeforeSubmit(true);
          return;
        }
      }

      await onSubmit(values);
    },
    [user?.timeZone, openBeforeSubmit, onSubmit],
  );

  return (
    <Form
      validate={yupValidate(validationSchema)}
      onSubmit={preSubmit}
      initialValues={getInitialData(initialData)}
      keepDirtyOnReinitialize={true}
      validateOnBlur
      render={(formProps) => {
        const isLoading = formProps.submitting || isSubmitting;
        setSubmitValidationFailed(
          formProps.submitFailed &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting,
        );
        return (
          <div>
            <BeforeSubmit
              title='Date in the past'
              open={openBeforeSubmit}
              onClose={() => setOpenBeforeSubmit(false)}
              submitProps={{
                onClick: async () => {
                  await onSubmit(formProps.values);
                },
                disabled: formProps.submitting,
                variant: 'contained',
                children: 'Ok',
              }}>
              Selected date is in the past. Please confirm if this is
              acceptable.
            </BeforeSubmit>
            <BeforeUnload
              when={formProps.dirty && !isLoading && !openBeforeSubmit}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={isLoading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await onSubmit(formProps.values);
                    onConfirm();
                  }}
                  disabled={formProps.submitting || !formProps.valid}>
                  {formProps.submitting ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <form
              noValidate
              data-testid='appointment-details-form'
              className={classes.mainFormBlock}>
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<string>
                  name='topic'
                  data-testid='appointment-details-topic'
                  component={TextFieldWrapper}
                  label='Topic*'
                  InputProps={{
                    inputProps: {
                      maxLength: 1024,
                      readOnly: !hasAccessToAction(
                        'appointment.details.update',
                      ),
                    },
                  }}
                />
              </FormGroup>
              <FieldDatesDetails
                readOnly={!hasAccessToAction('appointment.details.update')}
                minEndDate={
                  formProps.values?.start
                    ? new Date(formProps.values.start)
                    : undefined
                }
                defaultEndDuration={{ minutes: 30 }}
              />
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<string>
                  name='requestorFullName'
                  data-testid='appointment-details-requestor-name'
                  component={TextFieldWrapper}
                  label='Requestor Full Name*'
                  InputProps={{
                    inputProps: {
                      maxLength: 512,
                      readOnly: !hasAccessToAction(
                        'appointment.details.update',
                      ),
                    },
                  }}
                />
                <Field<string>
                  name='requestorEmail'
                  data-testid='appointment-details-requestor-email'
                  component={TextFieldWrapper}
                  label='Requestor Email*'
                  InputProps={{
                    inputProps: {
                      maxLength: 512,
                      readOnly: !hasAccessToAction(
                        'appointment.details.update',
                      ),
                    },
                  }}
                />
              </FormGroup>
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<Status>
                  testid='appointment-details-status'
                  name='status'
                  component={FormSelect}
                  label='Status*'
                  options={statusOptions}
                  readOnly={!hasAccessToAction('appointment.details.update')}
                />
                <Field<SearchSelectOption>
                  testid='appointment-details-advisor'
                  name='advisor'
                  component={SearchSelectWrapper}
                  label={`${rb('advisor-u')}*`}
                  isEqual={isEqual}
                  onSearch={async (searchString: string) => {
                    if (!user) return [];
                    const advisors = searchString
                      ? await Advisors.search(
                          user.id,
                          user.timeZone,
                          searchString,
                        )
                      : await Advisors.getByStatus(
                          user.id,
                          user.timeZone,
                          'Active',
                          0,
                        );
                    return advisors.map((advisor) => ({
                      label: `${advisor.firstName} ${advisor.lastName}`,
                      value: advisor.id,
                    }));
                  }}
                  readOnly={!hasAccessToAction('appointment.details.update')}
                />
              </FormGroup>
              {showLocation && (
                <FormGroup mobile={checkSizeTablet(800)}>
                  <Field<string>
                    name='location'
                    data-testid='appointment-details-location'
                    component={TextFieldWrapper}
                    label='Location*'
                    InputProps={{
                      inputProps: {
                        maxLength: 512,
                        readOnly: true,
                      },
                      endAdornment: (
                        <CopyButton
                          text={formProps.values?.location || 'No location'}
                        />
                      ),
                    }}
                  />
                </FormGroup>
              )}
              <FormGroup mobile={checkSizeTablet(800)}>
                <Field<string>
                  name='agenda'
                  component={TextFieldWysiwyg}
                  label='Agenda*'
                  placeholder='Type here...'
                  testid='appointment-details-agenda'
                />
              </FormGroup>
              {hasAccessToAction('appointment.details.update') && (
                <div className={classes.actionsBlock}>
                  <StickyContent>
                    <Button
                      data-testid='appointment-details-button-submit'
                      className={CLASS_TRACKING.INTERNAL_ACTION}
                      onClick={formProps.handleSubmit}
                      disabled={formProps.submitting}
                      startIcon={<CheckIcon />}>
                      {formProps.submitting ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </StickyContent>
                </div>
              )}
            </form>
          </div>
        );
      }}
    />
  );
}

export default AppointmentForm;
